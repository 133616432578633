/**
 *
 *
 * ToggleMode
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ToggleModeWrapper from './ToggleModeWrapper';

const ToggleMode = props => {
  const label = props.isPreviewMode
    ? 'components.Wysiwyg.ToggleMode.markdown'
    : 'components.Wysiwyg.ToggleMode.preview';
  const [t] = useTranslation();

  return (
    <ToggleModeWrapper>
      <button type="button" className="toggleModeButton" onClick={props.onClick}>
        {t(label)}
      </button>
    </ToggleModeWrapper>
  );
};

ToggleMode.defaultProps = {
  isPreviewMode: false,
  onClick: () => {},
};

ToggleMode.propTypes = {
  isPreviewMode: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ToggleMode;
