import {
  UserActionType,
  SET_USER_TOKEN,
  SET_IS_USER_AUTH,
  User,
  SET_USER,
  USER_LOGIN,
  USER_LOGOUT,
  SET_PARTNER,
} from './types';
import { Dispatch } from 'react';
import { setToken, setUserState } from '../../utils/userStorage';
import apiClient from '../../utils/feathersClient';

export function setUserToken(token: string): UserActionType {
  setToken(token);
  return {
    type: SET_USER_TOKEN,
    payload: token,
  };
}

export function setIsAuthUser(isAuth: boolean): UserActionType {
  return {
    type: SET_IS_USER_AUTH,
    payload: isAuth,
  };
}

export function setUser(user: User): UserActionType {
  setUserState(user);
  return {
    type: SET_USER,
    payload: user,
  };
}

export const checkIsUserAuth = () => async (dispatch: Dispatch<UserActionType>): Promise<void> => {
  try {
    const { accessToken, user, partner } = await apiClient.authenticate();
    dispatch({
      type: SET_IS_USER_AUTH,
      payload: true,
    });
    if (user) {
      dispatch({
        type: SET_USER,
        payload: user,
      });
    }
    if (partner) {
      dispatch({
        type: SET_PARTNER,
        payload: partner,
      });
    }
    setUserToken(accessToken);
  } catch (e) {
    dispatch({
      type: SET_IS_USER_AUTH,
      payload: false,
    });
    dispatch({
      type: USER_LOGOUT,
      payload: null,
    });
    console.log('catch the belt', e);
  }
};

export const userLogin = (payload: any) => async (
  dispatch: Dispatch<UserActionType>
): Promise<void> => {
  const { accessToken, user, partner } = await apiClient.authenticate({
    username: payload.username,
    password: payload.password,
    strategy: 'admin',
  });
  dispatch({
    type: SET_USER,
    payload: user,
  });
  dispatch({
    type: SET_PARTNER,
    payload: partner || {},
  });

  dispatch({
    type: USER_LOGIN,
    payload: {
      type: 'admin',
      status: 'ok',
      isAuth: true,
      token: accessToken,
    },
  });

  setUserState(user);
  setToken(accessToken);
};

export const resetPassword = (email: string) => async () => {
  await apiClient.service('reset-password').create({ email });
};

export const userLogout = () => async (dispatch: Dispatch<UserActionType>) => {
  // await apiClient.logout();
  setUserState({});
  setToken('');
  dispatch({
    type: USER_LOGOUT,
    payload: null,
  });
};
