/*
 *
 * HomePage
 *
 */
import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from '@buffetjs/core';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import PageTitle from 'components/PageTitle';

import { CustomRow, TableHeaders } from 'types/common';
import { SettingsItem } from 'types/pages/settings';

const HomePage: React.FC<{}> = () => {
  const history = useHistory();
  const [t] = useTranslation();

  const rows = [
    { id: 1, title: 'Content settings', url: '/settings/content' },
    { id: 2, title: 'Notifications settings', url: '/settings/notifications' },
  ];

  const customRow = ({ row }: CustomRow<SettingsItem>) => {
    const { id, title, url } = row;

    return (
      <tr onClick={() => history.push(url)}>
        <td>
          <p>{id}</p>
        </td>
        <td>
          <p>{title}</p>
        </td>
      </tr>
    );
  };

  return (
    <>
      <PageTitle title={t('HomePage.helmet.title')} />

      <div>
        <Row justify="space-between" align="middle">
          <div>
            <h1>Settings</h1>
          </div>
        </Row>

        <Row className="mt40">
          <Table headers={headers} rows={rows} customRow={customRow} />
        </Row>
      </div>
    </>
  );
};

const headers: TableHeaders<SettingsItem> = [
  {
    name: '№',
    value: 'id',
  },
  {
    name: 'Title',
    value: 'title',
  },
];

export default memo(HomePage);
