import { Roles } from 'types/common';

export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const SET_IS_USER_AUTH = 'SET_IS_USER_AUTH';
export const SET_USER = 'SET_USER';
export const SET_PARTNER = 'SET_PARTNER';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';

export interface User {
  id?: number | string;
  username?: string;
  email?: string;
  createdAt?: Date | string;
  updatedAt?: Date | string;
  isVerified?: boolean;
  firstName?: string;
  lastName?: string;
  patronymic?: string;
  phone?: string;
  role?: Roles;
  companyId?: string;
  status?: 'active' | 'unactive';
}

export interface Partner {
  id: number | string;
  username?: string;
  email?: string;
  createdAt?: Date | string;
  updatedAt?: Date | string;
  displayName?: string;
  type?: Roles;
  isActive: boolean;
}

export interface UserState {
  user: User;
  partner: Partial<Partner>;
  token: string | null;
  isAuth: boolean;

  status?: 'ok' | 'error';
  type?: string;
  currentAuthority?: 'user' | 'guest' | 'admin';
}

interface SetUserToken {
  type: typeof SET_USER_TOKEN;
  payload: string;
}

interface SetIsUserAuth {
  type: typeof SET_IS_USER_AUTH;
  payload: boolean;
}

interface SetUser {
  type: typeof SET_USER;
  payload: User;
}

interface SetPartner {
  type: typeof SET_PARTNER;
  payload: Partner;
}

interface UserLogin {
  type: typeof USER_LOGIN;
  payload: {
    status?: 'ok' | 'error';
    type?: string;
    currentAuthority?: 'user' | 'guest' | 'admin';
    isAuth?: boolean;
    token?: string;
  };
}

interface UserLogout {
  type: typeof USER_LOGOUT;
  payload: null;
}

export type UserActionType =
  | SetUserToken
  | SetIsUserAuth
  | SetUser
  | SetPartner
  | UserLogin
  | UserLogout;
