/*
 *
 * HomePage
 *
 */
import React, { useState, useEffect, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { Button, InputText, Select } from '@buffetjs/core';
import { useTranslation } from 'react-i18next';
import { Row, Col, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import PageTitle from 'components/PageTitle';
import Loading from 'components/Loading';
import LanguagesSelect from 'components/LanguageSelect';

import apiClient from 'utils/feathersClient';

import { SelectLanguageItem, TransformData } from 'types/common';
import { ContentSettings } from 'types/pages/settings';

const ContentSettingsForm: React.FC<{}> = () => {
  const history = useHistory();
  const [t] = useTranslation();

  const [contentSettings, setContentSettings] = useState<ContentSettings | {}>({});
  const [languages, setLanguages] = useState<SelectLanguageItem[]>([]);
  const [language, setLanguage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async () => {
    setIsLoading(true);
    const result: ContentSettings = await apiClient
      .service('content-settings')
      .find({ query: { $getAllLang: true } });

    const contentSettingsData = result;

    const languagesResult: SelectLanguageItem[] = await apiClient
      .service('languages')
      .find({ query: { $limit: -1 } });

    setLanguages(languagesResult);
    languagesResult.forEach((lang: SelectLanguageItem) => {
      contentSettingsData[lang.name] = contentSettingsData[lang.name] || {};
    });
    const defaultLang = languagesResult.find((e: SelectLanguageItem) => e.isDefault);
    if (defaultLang) {
      setLanguage(defaultLang.name);
    }

    setContentSettings(result);
    setIsLoading(false);
  };

  const fetchLanguages = async () => {};

  const transformData: TransformData<ContentSettings> = values => {
    return {
      ...values,
      workoutsTitle: values.workoutsTitle || '',
    };
  };

  const onSave = async (values: Partial<ContentSettings>) => {
    try {
      await apiClient.service('content-settings').create(transformData(values));
      message.success('Successfully updated!');
      history.push('/settings');
    } catch (e) {
      message.error(`Error while updating settings! ${e}`);
    }
  };

  useEffect(() => {
    fetchData();
    fetchLanguages();
  }, []);

  return (
    <>
      <PageTitle title={t('HomePage.helmet.title')} />

      <div className="back-button" onClick={() => history.push('/settings')}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>

      <Form
        onSubmit={onSave}
        initialValues={contentSettings}
        render={({ handleSubmit, pristine, submitting, form: { reset } }) => (
          <div>
            <Row justify="space-between" align="middle">
              <h1>Content settings</h1>
              <div className="align-center">
                <LanguagesSelect
                  className="mr25"
                  initialValue={language}
                  onSelect={value => setLanguage(value.name)}
                />
                <Button className="mr15" color="cancel" label="Reset" onClick={reset} />
                <Button
                  color="primary"
                  label="Save"
                  disabled={pristine || submitting}
                  onClick={handleSubmit}
                />
              </div>
            </Row>

            <Row className="mt40">
              {isLoading ? (
                <Loading style={{ minHeight: '280px' }} />
              ) : (
                <form className="form-container">
                  <Row>
                    <Col span={10}>
                      <Field name={`${language}.workoutsTitle`}>
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="workoutsTitle">Modes title</label>
                            <InputText
                              {...input}
                              id="workoutsTitle"
                              className={
                                (meta.error || meta.submitError) && meta.touched
                                  ? 'invalid-field'
                                  : ''
                              }
                              name="input"
                              type="text"
                            />
                            <br />
                            {!input.value.includes('[firstName]') && (
                              <Button
                                className="mr10"
                                onClick={() => input.onChange(input.value + '[firstName]')}
                              >
                                First Name
                              </Button>
                            )}
                            {!input.value.includes('[lastName]') && (
                              <Button onClick={() => input.onChange(input.value + '[lastName]')}>
                                Last Name
                              </Button>
                            )}
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>

                      <Field name="languageId">
                        {({ input }) => (
                          <div className="form-item">
                            <label htmlFor="languageId">Default language</label>
                            <Select
                              {...input}
                              id="languageId"
                              name="select"
                              options={languages.map(e => ({ label: e.name, value: e.id }))}
                            />
                          </div>
                        )}
                      </Field>
                    </Col>
                  </Row>
                </form>
              )}
            </Row>
          </div>
        )}
      />
    </>
  );
};

export default memo(ContentSettingsForm);
