import { User } from 'store/user/types';

// User
const userKey = 'userObject';

export function getUserState() {
  const value = localStorage.getItem(userKey);
  return value === 'undefined' || !value ? undefined : JSON.parse(value);
}

export function setUserState(data: User) {
  return localStorage.setItem(userKey, JSON.stringify(data));
}

export function removeUserState() {
  return localStorage.removeItem(userKey);
}

// Token
const tokenKey = 'userToken';

export function getToken() {
  const value = localStorage.getItem(tokenKey);
  return value === 'undefined' || !value ? undefined : value;
}

export function setToken(data: string) {
  return localStorage.setItem(tokenKey, data);
}

export function removeToken() {
  return localStorage.removeItem(tokenKey);
}
