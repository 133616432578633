import React from 'react';
import ReactSelect from 'react-select-async-paginate';

import apiClient from 'utils/feathersClient';

import { ApiListData } from 'types/common';
import { CharitiesItem } from 'types/pages/charities';

type CharitySelectProps = {
  value: CharitiesItem;
  onChange: (value: CharitiesItem) => void;
  placeholder?: string;
  className?: string;
  isDisabled?: boolean;
};

const CharitySelect: React.FC<CharitySelectProps> = props => {
  const fetchCharities = async (search: string, prevOptions: CharitiesItem[], additional: any) => {
    const limit = 15;
    const page = prevOptions.length / limit;
    if (additional && page * limit >= additional.total) {
      return {
        options: [] as any[],
        hasMore: false,
      };
    }

    const query: any = {
      $limit: limit,
      $skip: page * limit,
    };
    if (search) {
      query.$search = search;
    }
    const result: ApiListData<CharitiesItem> = await apiClient
      .service('charity-organizations')
      .find({ query });

    return {
      options: result.data,
      hasMore: page * limit < result.total,
      additional: {
        total: result.total,
      },
    };
  };

  return (
    // @ts-ignore
    <ReactSelect
      {...props}
      isClearable
      id="charity"
      className="async-select"
      classNamePrefix="async-select"
      getOptionLabel={item => (item ? item.name : null)}
      getOptionValue={item => (item ? item.id.toString() : null)}
      maxMenuHeight={230}
      loadOptions={(...args) => fetchCharities(...args)}
    />
  );
};

export default CharitySelect;
