/*
 *
 * HomeLeague
 *
 */
import React, { useState, useEffect, memo } from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Button, InputText } from '@buffetjs/core';
import { useTranslation } from 'react-i18next';
import { message, Row, Col } from 'antd';

import PageTitle from 'components/PageTitle';
import Wysiwyg from 'components/_strapi/Wysiwyg';
import Loading from 'components/Loading';
import LanguagesSelect from 'components/LanguageSelect';

import apiClient from 'utils/feathersClient';

import { LeaguesInfo } from 'types/pages/leagues';

import './form.css';
import { ValidationErrorsObject } from 'types/common';

const LeaguesForm: React.FC<{}> = () => {
  const [t] = useTranslation();

  const [item, setItem] = useState<LeaguesInfo>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>('en');

  const fetchData = async () => {
    setIsLoading(true);
    const result: LeaguesInfo = await apiClient
      .service('league-info')
      .find({ query: { $getAllLang: true } });

    setItem(result);
    setIsLoading(false);
  };

  const onSave = async (values: LeaguesInfo) => {
    try {
      await await apiClient.service('league-info').create(values);
      message.success('Successfully updated!');
    } catch (e) {
      message.error(`Error while updating league! ${e}`);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <PageTitle title={t('HomePage.helmet.title')} />

      <Form
        onSubmit={onSave}
        mutators={{
          // potentially other mutators could be merged here
          ...arrayMutators,
        }}
        validate={validate}
        initialValues={item}
        render={({ handleSubmit, pristine, submitting, form: { reset } }) => (
          <div>
            <Row justify="space-between">
              <h1>Leagues</h1>
              <div className="align-center">
                <LanguagesSelect
                  className="mr25"
                  initialValue={language}
                  onSelect={value => setLanguage(value.name)}
                />
                <Button className="mr15" color="cancel" label="Reset" onClick={reset} />
                <Button
                  color="primary"
                  label="Save"
                  disabled={pristine || submitting}
                  onClick={handleSubmit}
                />
              </div>
            </Row>

            <Row className="mt40">
              {isLoading ? (
                <Loading style={{ minHeight: '280px' }} />
              ) : (
                <form className="form-container">
                  <Row className="mb40">
                    <Col span={14}>
                      <Field name={`${language}.description`} validate={singleValidation}>
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="description">Description</label>
                            <Wysiwyg
                              {...input}
                              className={`league-main-description ${
                                (meta.error || meta.submitError) && meta.touched
                                  ? 'invalid-field'
                                  : ''
                              }`}
                              name="desc-wywiwyg"
                              lang={language}
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>
                  </Row>

                  <Row>
                    <Field name="validationError">
                      {({ meta }) =>
                        meta.error && meta.submitFailed ? (
                          <span className="validationError">{meta.error}</span>
                        ) : null
                      }
                    </Field>
                  </Row>

                  <Row>
                    <Col span={16} className="leagues-list">
                      <FieldArray name={`${language}.leagues`}>
                        {({ fields }) =>
                          fields.map((el, i) => (
                            <div className="leagues-row" key={i}>
                              <Field name={`${el}.name`}>
                                {({ input }) => (
                                  <div className="form-item">
                                    <label htmlFor={`${el}.name`}>Name</label>
                                    <InputText
                                      {...input}
                                      id={`${el}.name`}
                                      name="input"
                                      type="text"
                                      disabled
                                    />
                                  </div>
                                )}
                              </Field>
                              <Field name={`${el}.description`} validate={singleValidation}>
                                {({ input, meta }) => (
                                  <div className="form-item">
                                    <label htmlFor={`leagueDescription-${i}`}>Description</label>
                                    <Wysiwyg
                                      {...input}
                                      className={`league-item-description ${
                                        (meta.error || meta.submitError) && meta.touched
                                          ? 'invalid-field'
                                          : ''
                                      }`}
                                      name="desc-wywiwyg"
                                      lang={language}
                                    />
                                    {(meta.error || meta.submitError) && meta.touched && (
                                      <span className="error-message">{meta.error}</span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </div>
                          ))
                        }
                      </FieldArray>
                    </Col>
                  </Row>
                </form>
              )}
            </Row>
          </div>
        )}
      />
    </>
  );
};

const validate = (values: Partial<LeaguesInfo>) => {
  const errors: ValidationErrorsObject<LeaguesInfo> & { validationError?: string } = {};
  if (values) {
    Object.values(values).forEach(el => {
      if (el.leagues.some(league => !league.description)) {
        errors.validationError = 'There are empty description fields (maybe in other languages)!';
      }
    });
  }
  return errors;
};

const singleValidation = (value: string) => {
  let error = '';
  if (!value || !value.trim()) {
    error = 'Required field!';
  }
  return error;
};

export default memo(LeaguesForm);
