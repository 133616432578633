/*
 *
 * HomePage
 *
 */
import React, { useState, useEffect, memo } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { Button, InputText, Textarea } from '@buffetjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { message, Col, Row, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import PageTitle from 'components/PageTitle';
import Loading from 'components/Loading';
import LanguagesSelect from 'components/LanguageSelect';
import FileUploader from 'components/FileUploader';

import apiClient from 'utils/feathersClient';

import { FaqsItem } from 'types/pages/faqs';

const TroubleshootingsForm: React.FC<{}> = () => {
  const history = useHistory();
  const {
    params: { id: troubleshootingId },
  } = useRouteMatch();
  const [t] = useTranslation();

  const [troubleshooting, setTroubleshooting] = useState<Partial<FaqsItem>>({});
  // const [initialPageValues, setInitialPageValues] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>('en');

  const isEdit = troubleshootingId ? true : false;

  const fetchData = async () => {
    setIsLoading(true);
    const result: FaqsItem = await apiClient
      .service('content/faqs')
      .get(troubleshootingId, { query: { $getAllLang: true } });

    setTroubleshooting(result);
    setIsLoading(false);
  };

  const onSave = async (values: Partial<FaqsItem>) => {
    try {
      await apiClient.service('content/faqs').patch(troubleshootingId, values);
      message.success('Updated successfully!');
      history.push('/troubleshootings');
    } catch (e) {
      message.error(`Error while updating troubleshooting! ${e}`);
    }
  };

  const onAdd = async (values: Partial<FaqsItem>) => {
    try {
      await apiClient.service('content/faqs').create({ ...values, type: 'troubleshooting' });
      message.success('Added successfully!');
      history.push('/troubleshootings');
    } catch (e) {
      message.error(`Error while adding troubleshooting! ${e}`);
    }
  };

  const onDelete = async () => {
    try {
      await apiClient.service('content/faqs').remove(troubleshootingId);
      message.info('Deleted successfully!');
      history.push('/troubleshootings');
    } catch (e) {
      message.error(`Error while deleting troubleshooting! ${e}`);
    }
  };

  const openDeletePopup = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: onDelete,
    });
  };

  useEffect(() => {
    if (isEdit) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageTitle title={t('HomePage.helmet.title')} />

      <div className="back-button" onClick={() => history.push('/troubleshootings')}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>

      <Form
        onSubmit={isEdit ? onSave : onAdd}
        initialValues={troubleshooting}
        render={({ handleSubmit, pristine, submitting, form: { reset } }) => (
          <div>
            <Row justify="space-between">
              {isEdit ? (
                <h1>Troubleshooting №{troubleshootingId}</h1>
              ) : (
                <h1>Add Troubleshooting</h1>
              )}
              <div className="align-center">
                <LanguagesSelect
                  className="mr25"
                  initialValue={language}
                  onSelect={value => setLanguage(value.name)}
                />
                {isEdit && (
                  <Button
                    color="delete"
                    label="Delete"
                    className="mr15"
                    icon={<FontAwesomeIcon icon={faTrashAlt} />}
                    onClick={openDeletePopup}
                  />
                )}
                <Button className="mr15" color="cancel" label="Reset" onClick={reset} />
                {isEdit ? (
                  <Button
                    color="primary"
                    label="Save"
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                  />
                ) : (
                  <Button
                    color="primary"
                    label="Add"
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                  />
                )}
              </div>
            </Row>

            <Row className="mt40">
              {isEdit && isLoading ? (
                <Loading style={{ minHeight: '280px' }} />
              ) : (
                <form className="form-container">
                  <Row>
                    <Col span={8}>
                      <Field name={`${language}.title`} validate={singleValidation}>
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="title">Title</label>
                            <InputText
                              {...input}
                              id="title"
                              className={
                                (meta.error || meta.submitError) && meta.touched
                                  ? 'invalid-field'
                                  : ''
                              }
                              name="input"
                              type="text"
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>

                    <Col span={8}>
                      <Field name={`${language}.description`}>
                        {({ input, meta }) => (
                          <div>
                            <label htmlFor="description">Description</label>
                            <Textarea
                              {...input}
                              style={{ height: '220px' }}
                              id="description"
                              name="input"
                              type="text"
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>

                    <Col span={8} className="photo-wrapper">
                      <Field name="photoId">
                        {({ input }) => (
                          <FileUploader
                            logoId={input.value || 0}
                            accept="image/jpeg, image/png"
                            onFileLoaded={input.onChange}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                </form>
              )}
            </Row>
          </div>
        )}
      />
    </>
  );
};

const singleValidation = (value: string | number): string => {
  let error = '';
  if (!value || (typeof value === 'string' && !value.trim())) {
    error = 'Required field!';
  }
  return error;
};

export default memo(TroubleshootingsForm);
