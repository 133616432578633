/*
 *
 * HomePage
 *
 */
import React, { useState, useEffect, memo } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Button, InputText, InputNumber, Select } from '@buffetjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { message, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import PageTitle from 'components/PageTitle';
import Wysiwyg from 'components/_strapi/Wysiwyg';
import Loading from 'components/Loading';
import LanguagesSelect from 'components/LanguageSelect';
import FileUploader from 'components/FileUploader';

import apiClient from 'utils/feathersClient';

import { TransformData } from 'types/common';
import { WorkoutsItem } from 'types/pages/workouts';

import './form.css';

const WorkoutsForm: React.FC<{}> = () => {
  const history = useHistory();
  const {
    params: { id: workoutId },
  } = useRouteMatch();
  const [t] = useTranslation();

  const [workout, setWorkout] = useState<Partial<WorkoutsItem>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>('en');

  const isTutorial = workout.level === 0;

  const fetchData = async () => {
    setIsLoading(true);
    const result: WorkoutsItem = await apiClient
      .service('content/workouts')
      .get(workoutId, { query: { $getAllLang: true } });

    result.videos = result.videos || [];

    setWorkout(result);
    setIsLoading(false);
  };

  const transformData: TransformData<WorkoutsItem> = values => {
    const updateData = { ...values };

    return {
      ...values,
      ...updateData,
    };
  };

  const onSave = async (values: Partial<WorkoutsItem>) => {
    try {
      await apiClient.service('content/workouts').patch(workoutId, transformData(values));
      message.success('Updated successfully!');
      history.push('/modes');
    } catch (e) {
      message.error(`Error while updating mode! ${e}`);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageTitle title={t('HomePage.helmet.title')} />

      <div className="back-button" onClick={() => history.push('/modes')}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>

      <Form
        onSubmit={onSave}
        mutators={{
          // potentially other mutators could be merged here
          ...arrayMutators,
        }}
        initialValues={workout}
        render={({
          handleSubmit,
          pristine,
          submitting,
          form: {
            reset,
            mutators: { push },
          },
        }) => (
          <div>
            <Row justify="space-between">
              <h1>Mode №{workout.level}</h1>
              <div className="align-center">
                <LanguagesSelect
                  className="mr25"
                  initialValue={language}
                  onSelect={value => setLanguage(value.name)}
                />
                <Button className="mr15" color="cancel" label="Reset" onClick={reset} />
                <Button
                  color="primary"
                  label="Save"
                  disabled={pristine || submitting}
                  onClick={handleSubmit}
                />
              </div>
            </Row>

            <Row className="mt40">
              {isLoading ? (
                <Loading style={{ minHeight: '280px' }} />
              ) : (
                <form className="form-container">
                  <Row className="row">
                    <Col span={16}>
                      <Row className="nomargin-row">
                        <Col span={12}>
                          <Field name={`${language}.title`} validate={singleValidation}>
                            {({ input, meta }) => (
                              <div className="form-item">
                                <label htmlFor="title">Title</label>
                                <InputText
                                  {...input}
                                  id="title"
                                  className={
                                    (meta.error || meta.submitError) && meta.touched
                                      ? 'invalid-field'
                                      : ''
                                  }
                                  name="input"
                                  type="text"
                                />
                                {(meta.error || meta.submitError) && meta.touched && (
                                  <span className="error-message">{meta.error}</span>
                                )}
                              </div>
                            )}
                          </Field>
                          {!isTutorial && (
                            <>
                              <Field name="level">
                                {({ input, meta }) => (
                                  <div className="form-item">
                                    <label htmlFor="level">Level</label>
                                    <Select
                                      {...input}
                                      disabled={true}
                                      id="level"
                                      name="select"
                                      options={levelOptions}
                                    />
                                    {(meta.error || meta.submitError) && meta.touched && (
                                      <span className="error-message">{meta.error}</span>
                                    )}
                                  </div>
                                )}
                              </Field>
                              <Field name="duration">
                                {({ input, meta }) => (
                                  <div className="form-item">
                                    <label htmlFor="duration">Duration</label>
                                    <InputNumber
                                      {...input}
                                      id="duration"
                                      className={
                                        (meta.error || meta.submitError) && meta.touched
                                          ? 'invalid-field'
                                          : ''
                                      }
                                      name="input"
                                      type="text"
                                    />
                                    {(meta.error || meta.submitError) && meta.touched && (
                                      <span className="error-message">{meta.error}</span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </>
                          )}
                        </Col>
                        <Col span={12}>
                          {!isTutorial && (
                            <>
                              <Field name="hits">
                                {({ input, meta }) => (
                                  <div className="form-item">
                                    <label htmlFor="hits">Hits</label>
                                    <InputNumber
                                      {...input}
                                      id="hits"
                                      className={
                                        (meta.error || meta.submitError) && meta.touched
                                          ? 'invalid-field'
                                          : ''
                                      }
                                      name="input"
                                      type="number"
                                    />
                                    {(meta.error || meta.submitError) && meta.touched && (
                                      <span className="error-message">{meta.error}</span>
                                    )}
                                  </div>
                                )}
                              </Field>
                              <Field name="hitPower">
                                {({ input, meta }) => (
                                  <div className="form-item">
                                    <label htmlFor="hitPower">Hit power</label>
                                    <InputNumber
                                      {...input}
                                      id="hitPower"
                                      className={
                                        (meta.error || meta.submitError) && meta.touched
                                          ? 'invalid-field'
                                          : ''
                                      }
                                      name="input"
                                      type="number"
                                    />
                                    {(meta.error || meta.submitError) && meta.touched && (
                                      <span className="error-message">{meta.error}</span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </>
                          )}
                        </Col>
                      </Row>

                      {/* video */}
                      <Row className="mt15 nomargin-row">
                        <Col span={24}>
                          <FieldArray name="videos">
                            {({ fields }) => (
                              <div>
                                <h3>Videos</h3>
                                {fields.map((e, i) => (
                                  <div key={i} className="mt15">
                                    <div className="video-head">
                                      <h4>Video {i + 1}</h4>
                                      <Button
                                        className="trash-button"
                                        color="delete"
                                        icon={<FontAwesomeIcon icon={faTrashAlt} />}
                                        onClick={() => fields.remove(i)}
                                      />
                                    </div>

                                    <Row className="nomargin-row">
                                      <Col span={16} style={{ minWidth: 'calc(100% - 195px)' }}>
                                        <Field
                                          name={`${e}.${language}.title`}
                                          validate={singleValidation}
                                        >
                                          {({ input, meta }) => (
                                            <div className="form-item">
                                              <label htmlFor={`video-${i}.title`}>Title</label>
                                              <InputText
                                                {...input}
                                                id={`video-${i}.title`}
                                                className={
                                                  (meta.error || meta.submitError) && meta.touched
                                                    ? 'invalid-field'
                                                    : ''
                                                }
                                                name="input"
                                                type="text"
                                              />
                                              {(meta.error || meta.submitError) && meta.touched && (
                                                <span className="error-message">{meta.error}</span>
                                              )}
                                            </div>
                                          )}
                                        </Field>
                                        <Field name={`${e}.url`} validate={singleValidation}>
                                          {({ input, meta }) => (
                                            <div className="form-item">
                                              <label htmlFor={`video-${i}.url`}>
                                                YouTube Video ID
                                              </label>
                                              <InputText
                                                {...input}
                                                id={`video-${i}.url`}
                                                className={
                                                  (meta.error || meta.submitError) && meta.touched
                                                    ? 'invalid-field'
                                                    : ''
                                                }
                                                name="input"
                                                type="text"
                                              />
                                              {(meta.error || meta.submitError) && meta.touched && (
                                                <span className="error-message">{meta.error}</span>
                                              )}
                                            </div>
                                          )}
                                        </Field>
                                      </Col>
                                      <Col span={8} className="video-image-wrapper">
                                        <Field name={`${e}.imageId`}>
                                          {({ input }) => (
                                            <FileUploader
                                              logoId={input.value || 0}
                                              text="Video image"
                                              accept="image/jpeg, image/png"
                                              fontSize="16px"
                                              onFileLoaded={input.onChange}
                                            />
                                          )}
                                        </Field>
                                      </Col>
                                    </Row>
                                  </div>
                                ))}
                              </div>
                            )}
                          </FieldArray>

                          <div className="buttons mt15">
                            <Button
                              color="primary"
                              icon={<FontAwesomeIcon icon={faPlus} />}
                              onClick={() => push('videos', {})}
                            >
                              Add video
                            </Button>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt25 nomargin-row">
                        <Col span={24}>
                          <Field name={`${language}.description`}>
                            {({ input, meta }) => (
                              <div className="form-item">
                                <label>Description</label>
                                <Wysiwyg
                                  {...input}
                                  className={
                                    (meta.error || meta.submitError) && meta.touched
                                      ? 'invalid-field'
                                      : ''
                                  }
                                  name="description-wywiwyg"
                                  lang={language}
                                />
                                {(meta.error || meta.submitError) && meta.touched && (
                                  <span className="error-message">{meta.error}</span>
                                )}
                              </div>
                            )}
                          </Field>
                        </Col>
                      </Row>
                      <Row className="mt15 nomargin-row">
                        <Col span={24}>
                          <Field name={`${language}.shortDescription`}>
                            {({ input, meta }) => (
                              <div className="form-item">
                                <label>Short description</label>
                                <Wysiwyg
                                  {...input}
                                  className={
                                    (meta.error || meta.submitError) && meta.touched
                                      ? 'invalid-field'
                                      : ''
                                  }
                                  name="shortDescription-wywiwyg"
                                  lang={language}
                                />
                                {(meta.error || meta.submitError) && meta.touched && (
                                  <span className="error-message">{meta.error}</span>
                                )}
                              </div>
                            )}
                          </Field>
                        </Col>
                      </Row>
                    </Col>

                    {/* photo */}
                    <Col span={8} className="photo-wrapper">
                      <label>Exercise media</label>
                      <Field name="mediaId">
                        {({ input }) => (
                          <FileUploader
                            logoId={input.value || 0}
                            accept="image/jpeg, image/png"
                            onFileLoaded={input.onChange}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                </form>
              )}
            </Row>
          </div>
        )}
      />
    </>
  );
};

const levelOptions = [
  {
    label: 'Tutorial',
    value: 0,
  },
  {
    label: 'Balance',
    value: 1,
  },
  {
    label: 'Basic',
    value: 2,
  },
  {
    label: 'Boost',
    value: 3,
  },
  {
    label: 'Beast',
    value: 4,
  },
];

const singleValidation = (value: string | number) => {
  let error = '';
  if (!value || (typeof value === 'string' && !value.trim())) {
    error = 'Required field!';
  }
  return error;
};

export default memo(WorkoutsForm);
