import { getPageTitle } from '@ant-design/pro-layout';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import React from 'react';
import formatMessage from '../utils/formatMessage';
import defaultSettings from '../config/defaultSettings';
import styles from './UserLayout.module.less';

import LanguageDropdown from '../components/GlobalHeader/LanguageDropdown';

const UserLayout: React.FC<any> = props => {
  const {
    children,
    location = {
      pathname: '',
    },
  } = props;

  const title = getPageTitle({
    pathname: location.pathname,
    formatMessage,
    ...defaultSettings,
    ...props,
  });

  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={title} />
      </Helmet>

      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.header}>
              <div className={styles.lang}>
                <LanguageDropdown className="userlayout-language-dropdown" />
              </div>
            </div>
          </div>
          {children}
        </div>
      </div>
    </HelmetProvider>
  );
};

export default UserLayout;
