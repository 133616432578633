import React from 'react';
import ReactSelect from 'react-select-async-paginate';

import apiClient from 'utils/feathersClient';

import { ApiListData } from 'types/common';
import { CountriesItem } from 'types/components/countries';

import './index.css';

type CountrySelectProps = {
  value?: CountriesItem;
  onSelect: (value: CountriesItem) => void;
  placeholder?: string;
  className?: string;
};

const CountrySelect: React.FC<CountrySelectProps> = props => {
  const { onSelect } = props;

  const fetchCountries = async (search: string, prevOptions: CountriesItem[], additional: any) => {
    const limit = 15;
    const page = prevOptions.length / limit;
    if (additional && page * limit >= additional.total) {
      return {
        options: [] as any[],
        hasMore: false,
      };
    }

    const query: any = {
      $limit: limit,
      $skip: page * limit,
    };
    if (search) {
      query.$search = search;
    }
    const result: ApiListData<CountriesItem> = await apiClient.service('countries').find({ query });

    return {
      options: result.data,
      hasMore: page * limit < result.total,
      additional: {
        total: result.total,
      },
    };
  };

  return (
    <div>
      <label htmlFor="country">Country</label>
      {/* 
        // @ts-ignore */}
      <ReactSelect
        id="country"
        isClearable={true}
        className="country-select"
        classNamePrefix="async-select"
        getOptionLabel={item => (item ? item.name : null)}
        getOptionValue={item => (item ? item.id : null)}
        closeMenuOnSelect={false}
        maxMenuHeight={230}
        loadOptions={(...args) => fetchCountries(...args)}
        onChange={onSelect}
      />
    </div>
  );
};

export default CountrySelect;
