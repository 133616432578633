import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, Spin } from 'antd';
import { ClickParam } from 'antd/es/menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import HeaderDropdown from '../HeaderDropdown';

import { userLogout } from 'store/user/actions';

import { RootState } from 'store/types';

import styles from './index.module.less';

interface AvatarDropdownProps extends RouteComponentProps {}

const AvatarDropdown: React.FC<AvatarDropdownProps> = props => {
  const { history } = props;
  const [t] = useTranslation();

  const { user: currentUser, partner: currentPartner } = useSelector<RootState, RootState['user']>(
    state => state.user
  );
  const dispatch = useDispatch();
  const onUserLogout = () => dispatch(userLogout());

  const onMenuClick = async (event: ClickParam) => {
    const { key } = event;

    if (key === 'profile' && currentPartner) {
      history.push(`/administrators/${currentPartner.id}`);
    }
    if (key === 'administrators') {
      history.push('/administrators');
      return;
    }
    if (key === 'logout') {
      await onUserLogout();

      history.push('/user/login');
      return;
    }
  };

  const menuHeaderDropdown = (
    <Menu className={`header-dropdown ${styles.menu}`} selectedKeys={[]} onClick={onMenuClick}>
      {currentUser.role === 'admin' && [
        <Menu.Item key="profile">{t('app.components.Logout.profile')}</Menu.Item>,
        <Menu.Item key="administrators">{t('app.components.Logout.admin')}</Menu.Item>,
      ]}
      <Menu.Item key="logout">
        <span style={{ color: '#f75b1d' }}>{t('app.components.Logout.logout')}</span>
        <FontAwesomeIcon style={{ marginLeft: '10px', color: '#f75b1d' }} icon={faSignOutAlt} />
      </Menu.Item>
    </Menu>
  );

  return currentUser && currentUser.id ? (
    <HeaderDropdown placement="bottomRight" trigger={['click']} overlay={menuHeaderDropdown}>
      <span className={`${styles.action} ${styles.account}`}>
        {/* <Avatar size="small" className={styles.avatar} src={currentUser.avatar} alt="avatar" /> */}
        <span className={styles.name} style={{ minWidth: '165px', textAlign: 'right' }}>
          {currentUser.username || currentUser.firstName || currentUser.lastName || '-----'}
          <FontAwesomeIcon style={{ marginLeft: '10px' }} icon={faCaretDown} />
        </span>
      </span>
    </HeaderDropdown>
  ) : (
    <span className={`${styles.action} ${styles.account}`}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );
};

export default withRouter(AvatarDropdown);
