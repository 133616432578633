/*
 *
 * HomePage
 *
 */
import React, { useState, useEffect, memo } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import TimePicker from 'rc-time-picker';
import { Form, Field } from 'react-final-form';
import DateTimePicker from 'react-datetime-picker';
import Slider from 'react-input-slider';
import { Button, InputText, InputNumber, Toggle, Textarea, Select } from '@buffetjs/core';
import moment, { Moment } from 'moment';
import { message, Modal, Row, Col } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faBars, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import PageTitle from 'components/PageTitle';
import FileUploader from 'components/FileUploader';
import InviteUsers from 'components/InviteUsers';
import Loading from 'components/Loading';
import LanguagesSelect from 'components/LanguageSelect';
import TagsSelect from 'components/TagsSelect';
import CharitySelect from 'components/CharitySelect';

import apiClient from 'utils/feathersClient';

import { TagsItem, ToggleEvent, ValidationErrorsObject, TransformData } from 'types/common';
import { ChallengesItem } from 'types/pages/challenges';
import { UsersItem } from 'types/pages/users';

import 'rc-time-picker/assets/index.css';

const ChallengesForm: React.FC<{}> = () => {
  const history = useHistory();
  const {
    params: { id: challengeId },
  } = useRouteMatch();
  const [t] = useTranslation();

  const [challenge, setChallenge] = useState<Partial<ChallengesItem>>({});
  const [inviteUsers, setInviteUsers] = useState<number[]>([]);
  const [removeMembers, setRemoveMembers] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [challengeStatus, setChallengeStatus] = useState<'started' | 'ended'>(null);
  const [language, setLanguage] = useState<string>('en');

  const isEdit = challengeId ? true : false;

  const fetchData = async () => {
    setIsLoading(true);
    const result: ChallengesItem = await apiClient.service('challenges').get(challengeId, {
      query: {
        $joins: {
          winningCriteria: true,
          challengeTags: true,
          charityOrganization: true,
        },
        $getAllLang: true,
      },
    });

    result.winningCriteria = result.winningCriteria || {
      type: 'byHits',
    };

    result.winningCriteria.trainTime =
      result.winningCriteria.trainTime || getSeconds(moment('09:00:00', 'HH:mm:ss'));

    setChallenge({
      ...result,
      invitedUsers: result.invitedUsers || [],
      removeMembers: [],
    });

    const challengeStatus =
      new Date(result.endDate) < new Date()
        ? 'ended'
        : new Date(result.startDate) < new Date()
        ? 'started'
        : null;
    setChallengeStatus(challengeStatus);
    setIsLoading(false);
  };

  const transformData: TransformData<ChallengesItem> = values => {
    const charityOrganizationId = values.charityOrganization ? values.charityOrganization.id : null;
    let winningCriteria = {};
    if (values.winningCriteria.type !== 'byHits') {
      winningCriteria = {
        ...values.winningCriteria,
        hitsCount: null,
      };
    }
    if (values.winningCriteria.type !== 'byTime') {
      winningCriteria = {
        ...values.winningCriteria,
        ...winningCriteria,
        trainTime: null,
      };
    }

    return {
      ...values,
      inviteUsers,
      removeMembers,
      winningCriteria,
      charityOrganizationId,
      charityOrganization: undefined,
      challengeTags: undefined,
      tags: values.challengeTags ? values.challengeTags.map((e: TagsItem) => e.title) : [],
    };
  };

  const onSave = async (values: Partial<ChallengesItem>) => {
    try {
      await apiClient.service('challenges').patch(challengeId, transformData(values));
      message.success('Updated successfully!');
      history.push('/challenges');
    } catch (e) {
      message.error(`Error while updating challenge! ${e}`);
    }
  };

  const onAdd = async (values: Partial<ChallengesItem>) => {
    try {
      await apiClient.service('challenges').create(transformData(values));
      message.success('Added successfully!');
      history.push('/challenges');
    } catch (e) {
      message.error(`Error while adding challenge! ${e}`);
    }
  };

  const onDelete = async () => {
    try {
      await apiClient.service('challenges').remove(challengeId);
      message.info('Deleted successfully!');
      history.push('/challenges');
    } catch (e) {
      message.error(`Error while deleting challenge! ${e}`);
    }
  };

  const openDeletePopup = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: onDelete,
    });
  };

  const bownceNow = (change: Function) => {
    change('startDate', moment());
    change('endDate', moment().add(1, 'days'));
    // setChallengeStatus('started');
  };

  const resetFormState = (reset: Function) => {
    reset();
    const challengeStatus =
      new Date(challenge.endDate) < new Date()
        ? 'ended'
        : new Date(challenge.startDate) < new Date()
        ? 'started'
        : null;
    setChallengeStatus(challengeStatus);
  };

  useEffect(() => {
    if (isEdit) {
      fetchData();
    } else {
      setChallenge({
        level: 0,
        superPrize: 0,
        winnersCount: 1,
        allocatedCoins: 0,
        charity: 0,
        winningCriteria: {
          type: 'byHits',
          trainTime: getSeconds(moment('09:00:00', 'HH:mm:ss')),
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageTitle title={t('HomePage.helmet.title')} />

      <div className="back-button" onClick={() => history.push('/challenges')}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>

      <Form
        onSubmit={isEdit ? onSave : onAdd}
        validate={validate}
        initialValues={challenge}
        render={({ handleSubmit, pristine, submitting, values, form: { reset, change } }) => (
          <div>
            <Row justify="space-between">
              {isEdit ? (
                <h1 className="align-center">
                  Challenge №{challengeId}
                  <Button
                    label="Posts"
                    className="posts-button"
                    color="secondary"
                    icon={<FontAwesomeIcon icon={faBars} />}
                    onClick={() => history.push(`/challenges/${challengeId}/posts`)}
                  />
                  {challengeStatus === 'started' ? (
                    <span style={{ color: '#007EFF' }}>&nbsp; Started!</span>
                  ) : null}
                  {challengeStatus === 'ended' ? (
                    <span style={{ color: '#F64D0A' }}>&nbsp; Ended!</span>
                  ) : null}
                </h1>
              ) : (
                <h1>Add challenge</h1>
              )}
              <div className="align-center">
                <LanguagesSelect
                  className="mr25"
                  initialValue={language}
                  onSelect={value => setLanguage(value.name)}
                />
                {isEdit && (
                  <Button
                    color="delete"
                    label="Delete"
                    className="mr15"
                    icon={<FontAwesomeIcon icon={faTrashAlt} />}
                    onClick={openDeletePopup}
                  />
                )}
                <Button
                  color="cancel"
                  label="Reset"
                  className="mr15"
                  disabled={challengeStatus === 'ended' || submitting || pristine}
                  onClick={() => resetFormState(reset)}
                />
                {isEdit ? (
                  <Button
                    className="mr15"
                    color="primary"
                    label="Save"
                    disabled={
                      challengeStatus === 'ended' || submitting || (pristine && !inviteUsers.length)
                    }
                    onClick={handleSubmit}
                  />
                ) : (
                  <Button
                    color="primary"
                    label="Add"
                    onClick={handleSubmit}
                    disabled={submitting || (pristine && !inviteUsers.length)}
                  />
                )}
              </div>
            </Row>
            <Row className="mt40">
              {isEdit && isLoading ? (
                <Loading style={{ minHeight: '400px' }} />
              ) : (
                <form className="form-container">
                  <Row>
                    <Col span={8}>
                      <Field name={`${language}.name`} validate={singleValidation}>
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="name">Name</label>
                            <InputText
                              {...input}
                              id="name"
                              className={
                                (meta.error || meta.submitError) && meta.touched
                                  ? 'invalid-field'
                                  : ''
                              }
                              name="input"
                              disabled={challengeStatus === 'ended'}
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name={`${language}.description`}>
                        {({ input }) => (
                          <div className="form-item">
                            <label htmlFor="description">Description</label>
                            <Textarea
                              {...input}
                              style={{ maxHeight: '115px', marginBottom: '-8px' }}
                              id="description"
                              name="input"
                              disabled={challengeStatus === 'ended'}
                            />
                          </div>
                        )}
                      </Field>
                      <Field name="challengeTags">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="challengeTags">Tags</label>
                            <TagsSelect {...input} />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="startDate">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="startDate">Start Date</label>
                            <DateTimePicker
                              className={
                                (meta.error || meta.submitError) && meta.touched
                                  ? 'invalid-date'
                                  : ''
                              }
                              disabled={!!challengeStatus}
                              value={input.value ? new Date(input.value) : ''}
                              onChange={input.onChange}
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="endDate">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="endDate">End Date</label>
                            <DateTimePicker
                              className={
                                (meta.error || meta.submitError) && meta.touched
                                  ? 'invalid-date'
                                  : ''
                              }
                              disabled={!!challengeStatus}
                              value={input.value ? new Date(input.value) : ''}
                              onChange={input.onChange}
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <div>
                        <Button
                          className="bowncenow-button"
                          disabled={!!challengeStatus}
                          onClick={() => bownceNow(change)}
                        >
                          bownce now
                        </Button>
                      </div>
                    </Col>

                    <Col span={8}>
                      <Field name="level">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="level">Level</label>
                            <Select
                              {...input}
                              id="level"
                              name="select"
                              options={levelOptions}
                              disabled={!!challengeStatus}
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="hitPower">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="hitPower">Hit Power</label>
                            <InputNumber
                              {...input}
                              id="hitPower"
                              className={
                                (meta.error || meta.submitError) && meta.touched
                                  ? 'invalid-field'
                                  : ''
                              }
                              disabled={!!challengeStatus}
                              name="input"
                              type="number"
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="type">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="type">Type</label>
                            <Select
                              {...input}
                              id="type"
                              name="select"
                              options={typeOptions}
                              disabled={!!challengeStatus}
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      {values.type === 'stake' && (
                        <>
                          <Field name="superPrize">
                            {({ input, meta }) => (
                              <div className="form-item">
                                <label htmlFor="superPrize">Super Prize</label>
                                <InputNumber
                                  {...input}
                                  id="superPrize"
                                  name="input"
                                  type="number"
                                  className={
                                    (meta.error || meta.submitError) && meta.touched
                                      ? 'invalid-field'
                                      : ''
                                  }
                                  disabled={!!challengeStatus}
                                />
                                {(meta.error || meta.submitError) && meta.touched && (
                                  <span className="error-message">{meta.error}</span>
                                )}
                              </div>
                            )}
                          </Field>

                          <Row className="form-row">
                            <Col span={12}>
                              <Field name="winnersCount">
                                {({ input, meta }) => (
                                  <div className="form-item">
                                    <label htmlFor="winnersCount">Winners Count</label>
                                    <InputNumber
                                      {...input}
                                      id="winnersCount"
                                      name="input"
                                      type="number"
                                      className={
                                        (meta.error || meta.submitError) && meta.touched
                                          ? 'invalid-field'
                                          : ''
                                      }
                                      disabled={!!challengeStatus}
                                    />
                                    {(meta.error || meta.submitError) && meta.touched && (
                                      <span className="error-message">{meta.error}</span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </Col>

                            <Col span={12}>
                              <Field name="allocatedCoins">
                                {({ input, meta }) => (
                                  <div className="form-item">
                                    <label htmlFor="allocatedCoins">Allocated Coins</label>
                                    <InputNumber
                                      {...input}
                                      id="allocatedCoins"
                                      name="input"
                                      type="number"
                                      className={
                                        (meta.error || meta.submitError) && meta.touched
                                          ? 'invalid-field'
                                          : ''
                                      }
                                      disabled={!!challengeStatus}
                                    />
                                    {(meta.error || meta.submitError) && meta.touched && (
                                      <span className="error-message">{meta.error}</span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </Col>
                          </Row>
                        </>
                      )}
                      <Field name="isPrivate">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="isPrivate">Private</label>
                            <Toggle
                              {...input}
                              onChange={(e: ToggleEvent) =>
                                !challengeStatus && input.onChange(e.target.value)
                              }
                              value={input.value || false}
                              id="isPrivate"
                              className={!!challengeStatus ? 'disable-toggle' : ''}
                              name="toggle"
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="charityOrganization">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="charityOrganization">Charity organization</label>
                            <CharitySelect isDisabled={!!challengeStatus} {...input} />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="charity">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="charity">
                              Charity -&nbsp;
                              <span style={{ color: '#007EFF', fontWeight: 'bold' }}>
                                {input.value}
                              </span>
                            </label>
                            <div className="form-slider">
                              <Slider
                                xstep={1}
                                xmin={0}
                                xmax={100}
                                x={input.value}
                                onChange={({ x }) => input.onChange(x)}
                                styles={{
                                  active: {
                                    backgroundColor: '#1C8DE7',
                                  },
                                  track: {
                                    backgroundColor: '#F1F1F1',
                                    height: '5px',
                                    margin: '0 2px',
                                    cursor: 'pointer',
                                  },
                                  thumb: {
                                    backgroundColor: '#007EFF',
                                  },
                                  disabled: {
                                    opacity: 0.7,
                                    cursor: 'default',
                                  },
                                }}
                                disabled={!!challengeStatus}
                                // @ts-ignore
                                className={!!challengeStatus ? 'slider-disabled' : ''}
                              />
                            </div>
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>

                    <Col span={8} className="photo-wrapper">
                      <Field name="photoId">
                        {({ input }) => (
                          <FileUploader
                            logoId={input.value || 0}
                            accept="image/jpeg, image/png"
                            disabled={!!challengeStatus}
                            onFileLoaded={input.onChange}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>

                  <div className="form-divider" />

                  <Row className="winning-criteria">
                    <Col span={8}>
                      <h2 className="winning-criteria__title">Winning Criteria</h2>

                      <Field name="winningCriteria.type">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="winningCriteria.type">Type</label>
                            <Select
                              {...input}
                              id="winningCriteria.type"
                              name="select"
                              options={winningCriteriaOptions}
                              disabled={!!challengeStatus}
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>

                    <Col span={8} className="winning-criteria__right-block">
                      {values.winningCriteria && values.winningCriteria.type === 'byHits' && (
                        <>
                          <div className="arrow-right" />
                          <Field name="winningCriteria.hitsCount">
                            {({ input, meta }) => (
                              <div className="form-item winning-criteria__item">
                                <label htmlFor="winningCriteria.hitsCount">Hits Count</label>
                                <InputNumber
                                  {...input}
                                  id="winningCriteria.hitsCount"
                                  name="input"
                                  type="number"
                                  className={
                                    (meta.error || meta.submitError) && meta.touched
                                      ? 'invalid-field'
                                      : ''
                                  }
                                  disabled={!!challengeStatus}
                                />
                                {(meta.error || meta.submitError) && meta.touched && (
                                  <span className="error-message">{meta.error}</span>
                                )}
                              </div>
                            )}
                          </Field>
                        </>
                      )}
                      {values.winningCriteria && values.winningCriteria.type === 'byTime' && (
                        <>
                          <div className="arrow-right" />
                          <Field name="winningCriteria.trainTime">
                            {({ input, meta }) => (
                              <div className="form-item winning-criteria__item">
                                <label htmlFor="winningCriteria.trainTime">Train Time</label>
                                <TimePicker
                                  {...input}
                                  disabled={!!challengeStatus}
                                  className={
                                    (meta.error || meta.submitError) && meta.touched
                                      ? 'invalid-field'
                                      : ''
                                  }
                                  // @ts-ignore
                                  value={
                                    input.value
                                      ? getFormattedTime(input.value)
                                      : moment('00:00:00', 'HH:mm:ss')
                                  }
                                  onChange={val => input.onChange(getSeconds(val))}
                                />
                                {(meta.error || meta.submitError) && meta.touched && (
                                  <span className="error-message">{meta.error}</span>
                                )}
                              </div>
                            )}
                          </Field>
                        </>
                      )}
                    </Col>
                  </Row>
                </form>
              )}
            </Row>
          </div>
        )}
      />

      <div className="row">
        <InviteUsers
          invitedUsers={challenge.invitedUsers}
          usersIds={challenge.usersIds}
          type="challenge"
          typeId={challengeId}
          onInviteChange={(users: UsersItem[]) => setInviteUsers(users.map((e: UsersItem) => e.id))}
          onRemoveChange={(users: UsersItem[]) =>
            setRemoveMembers(users.map((e: UsersItem) => e.id))
          }
        />
      </div>
    </>
  );
};

const typeOptions = ['free', 'stake'];

const levelOptions = [
  {
    label: 'All',
    value: '0',
  },
  {
    label: 'Bownce Balance',
    value: 1,
  },
  {
    label: 'Bownce Basic',
    value: 2,
  },
  {
    label: 'Bownce Boost',
    value: 3,
  },
  {
    label: 'Bownce Beast',
    value: 4,
  },
];

const winningCriteriaOptions = [
  {
    label: 'Total hits',
    value: 'byHits',
  },
  {
    label: 'Total time',
    value: 'byTime',
  },
  {
    label: 'Max hits',
    value: 'byFirstHits',
  },
  {
    label: 'Max time',
    value: 'byFirstTime',
  },
];

const validate = (values: Partial<ChallengesItem>) => {
  const errors: ValidationErrorsObject<ChallengesItem> = {};

  if (!values.startDate) {
    errors.startDate = 'Required field!';
  }
  if (!values.endDate) {
    errors.endDate = 'Required field!';
  }
  if (new Date(values.endDate) < new Date(values.startDate)) {
    errors.endDate = 'Should be more than start date!';
  }
  if (
    values.winningCriteria &&
    values.winningCriteria.type === 'byHits' &&
    (!values.winningCriteria.hitsCount || values.winningCriteria.hitsCount < 1)
  ) {
    errors.winningCriteria = {
      hitsCount: 'Should be more than 0!',
    };
  }
  if (
    values.winningCriteria &&
    values.winningCriteria.type === 'byTime' &&
    (!values.winningCriteria.trainTime || values.winningCriteria.trainTime < 1)
  ) {
    errors.winningCriteria = {
      trainTime: 'Should be more than 0!',
    };
  }
  if (values.type === 'stake') {
    if (!values.superPrize || values.superPrize < 10 || values.superPrize > 1000) {
      errors.superPrize = 'Enter winner price between 10 and 1000!';
    }
    if (!values || values.winnersCount < 1) {
      errors.winnersCount = 'Should be more than 0!';
    }
    if (!values || values.allocatedCoins < 0) {
      errors.winnersCount = 'Should be more than 0!';
    }
  }
  if (!values.hitPower || values.hitPower < 1) {
    errors.hitPower = 'Should be greater than 0!';
  }
  return errors;
};

const singleValidation = (value: string | number): string => {
  let error = '';
  if (!value || (typeof value === 'string' && !value.trim())) {
    error = 'Required field!';
  }
  return error;
};

const getSeconds = (momentObject: Moment): number => {
  if (!momentObject) {
    return null;
  }

  const arr = momentObject.format('HH:mm:ss').split(':').map(Number);
  const hoursSum = arr[0] * 60 * 60;
  const minutesSum = arr[1] * 60;
  const secondsSum = arr[2];

  return hoursSum + minutesSum + secondsSum;
};

const getFormattedTime = (timeInSeconds: number): Moment => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor(timeInSeconds / 60) - hours * 60;
  const seconds = timeInSeconds - (minutes * 60 + hours * 3600);
  const formattedValues = [hours, minutes, seconds].map(el => (el > 9 ? el : '0' + el));

  return moment(formattedValues.join(':'), 'HH:mm:ss');
};

export default memo(ChallengesForm);
