import React from 'react';
import { HashRouter as Router, Switch, Redirect } from 'react-router-dom';

import config from './config';

import AppRoute from './AppRouteHOC';

import { AppRouteProps } from 'types/common';

const filteredRoutes = config.filter(el => !(el.isDivider || el.isDropdown));

const Routes = () => (
  <Router>
    <Switch>
      <Redirect exact from="/" to="/groups" />
      {filteredRoutes.map((item: AppRouteProps) => (
        <AppRoute {...item} key={item.name} exact />
      ))}
      <Redirect to="/exception/404" />
    </Switch>
  </Router>
);

export default Routes;
