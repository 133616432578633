import React, { memo } from 'react';
import Helmet from 'react-helmet';

// import favicon from './favicon.ico';
type PageTitleProps = {
  title: string;
};

const PageTitle: React.FC<PageTitleProps> = ({ title }) => (
  <Helmet title={title} link={[{ rel: 'icon', type: 'image/png', href: './favicon.ico' }]} />
);

export default memo(PageTitle);
