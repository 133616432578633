/**
 * Ant Design Pro v4 use `@ant-design/pro-layout` to handle Layout.
 * You can view component api by:
 * https://github.com/ant-design/ant-design-pro-layout
 */

import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { ConfigProvider, Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import ProLayout, {
  MenuDataItem,
  BasicLayoutProps as ProLayoutProps,
} from '@ant-design/pro-layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

import RightContent from 'components/GlobalHeader/RightContent';

import Authorized from 'utils/Authorized';
import formatMessage from 'utils/formatMessage';
import defaultSettings from 'config/defaultSettings';
import routes from 'routes/config';

import logo from 'assets/icons/logo.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'store/types';

export interface BasicLayoutProps extends ProLayoutProps {
  route: ProLayoutProps['route'] & {
    authority: string[];
  };
}
/**
 * use Authorized check all menu item
 */

const menuDataRender = (menuList: MenuDataItem[]): MenuDataItem[] =>
  menuList.map(item => {
    const localItem = { ...item, children: item.children ? menuDataRender(item.children) : [] };
    return Authorized.check(item.authority, localItem, null) as MenuDataItem;
  });

const BasicLayout: React.FC<BasicLayoutProps> = props => {
  const { children } = props;
  const { path } = useRouteMatch();

  const { user } = useSelector<RootState, RootState['user']>(state => state.user);

  const filteredRoutes = routes.map(el =>
    el.roles && !el.roles.includes(user.role) ? { ...el, hideInMenu: true } : el
  );

  return (
    <ConfigProvider>
      <ProLayout
        logo={logo}
        formatMessage={formatMessage}
        menuHeaderRender={(logoDom, titleDom) => (
          <Link to="/">
            {logoDom}
            {titleDom}
          </Link>
        )}
        menuItemRender={(menuItemProps, defaultDom) => {
          if (menuItemProps.isDivider) {
            return <div className="custom-divider">{menuItemProps.name}</div>;
          }

          if (menuItemProps.isDropdown) {
            const isDropdownActive = menuItemProps.subItems.some((link: any) =>
              path.includes(link.path)
            );

            return (
              <Collapse
                className="menu-collapse"
                defaultActiveKey={isDropdownActive ? ['1'] : []}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              >
                <Collapse.Panel header={menuItemProps.name} key="1">
                  {menuItemProps.subItems
                    .filter((item: any) => !item.hideInMenu)
                    .map((link: any) => (
                      <Link
                        key={link.name}
                        to={link.path}
                        className={path.includes(link.path) ? 'isActive' : ''}
                      >
                        <FontAwesomeIcon icon={faCaretRight} />
                        <span>{link.name}</span>
                      </Link>
                    ))}
                </Collapse.Panel>
              </Collapse>
            );
          }

          return (
            <Link
              to={menuItemProps.path}
              className={path.includes(menuItemProps.path) ? 'isActive' : ''}
            >
              <FontAwesomeIcon icon={faCaretRight} />
              {defaultDom}
            </Link>
          );
        }}
        menuDataRender={menuDataRender}
        rightContentRender={() => <RightContent />}
        {...props}
        {...defaultSettings}
        route={{ routes: filteredRoutes }}
      >
        <div style={{ minHeight: '85vh' }}>{children}</div>
      </ProLayout>
    </ConfigProvider>
  );
};

export default BasicLayout;
