/**
 *
 *
 * PreviewControl
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompress } from '@fortawesome/free-solid-svg-icons';
import PreviewControlWrapper from './PreviewControlWrapper';

const PreviewControl = ({ onClick }) => {
  const [t] = useTranslation();

  return (
    <PreviewControlWrapper onClick={onClick}>
      <div />
      <div className="wysiwygCollapse">{t('components.Wysiwyg.collapse')}</div>
      <FontAwesomeIcon style={{ marginLeft: '7px' }} icon={faCompress} />
    </PreviewControlWrapper>
  );
};

PreviewControl.defaultProps = {
  onClick: () => {},
};

PreviewControl.propTypes = {
  onClick: PropTypes.func,
};

export default PreviewControl;
