import { MessageDescriptor } from '@ant-design/pro-layout/lib/typings';

const translates = {
  'menu.list.table-list': 'List',
};

export default (message: MessageDescriptor) => {
  // @ts-ignore
  return translates[message.id];
};
