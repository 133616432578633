import React from 'react';
import ReactSelect from 'react-select-async-paginate';

import apiClient from 'utils/feathersClient';

import { ApiListData } from 'types/common';
import { ChallengesItem } from 'types/pages/challenges';
import { GroupsItem } from 'types/pages/groups';

type CategorySelectProps = {
  value: ChallengesItem[] | GroupsItem[];
  onChange: (value: ChallengesItem[] | GroupsItem[]) => void;
  type: 'groups' | 'challenges';
  partnerId: number;
  placeholder?: string;
  className?: string;
};

const CategorySelect: React.FC<CategorySelectProps> = props => {
  const { type: categoryType, partnerId } = props;
  const fecthCategorySelect = async (
    search: string,
    prevOptions: ChallengesItem[] | GroupsItem[],
    additional: any
  ) => {
    const limit = 15;
    const page = prevOptions.length / limit;
    if (additional && page * limit >= additional.total) {
      return {
        options: [] as any[],
        hasMore: false,
      };
    }

    const query: any = {
      $limit: limit,
      $skip: page * limit,
      $partnerId: partnerId ? ['null', partnerId] : 'null',
    };
    if (search) {
      query.$search = search;
    }
    const result: ApiListData<GroupsItem[] | ChallengesItem[]> = await apiClient
      .service(categoryType)
      .find({ query });

    return {
      options: result.data,
      hasMore: page * limit < result.total,
      additional: {
        total: result.total,
      },
    };
  };

  return (
    // @ts-ignore
    <ReactSelect
      {...props}
      isMulti
      className="async-select"
      classNamePrefix="async-select"
      getOptionLabel={item => item.name || '-'}
      getOptionValue={item => item.id}
      closeMenuOnSelect={false}
      maxMenuHeight={230}
      loadOptions={(...args) => fecthCategorySelect(...args)}
    />
  );
};

export default CategorySelect;
