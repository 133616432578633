import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';

import HeaderDropdown from '../HeaderDropdown';

import { setActiveLanguage } from 'store/global/actions';

import { ClickParam } from 'antd/es/menu';
import { RootState } from 'store/types';

import styles from './index.module.less';

interface AvatarDropdownProps extends RouteComponentProps {
  className?: string;
}

const AvatarDropdown: React.FC<AvatarDropdownProps> = props => {
  const { activeLanguage } = useSelector<RootState, RootState['global']>(state => state.global);

  const dispatch = useDispatch();
  const handleActiveLanguage = (payload: string) => dispatch(setActiveLanguage(payload));

  const { i18n } = useTranslation();

  const onSetActiveLanguage = (language: string) => {
    i18n.changeLanguage(language);
    handleActiveLanguage(language);
  };

  const getFlagUrl = (locale: string) => {
    switch (locale) {
      case 'en':
        return 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/us.svg';
      case 'pt-BR':
        return 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/br.svg';
      case 'zh':
        return 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/tw.svg';
      case 'zh-Hans':
        return 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/cn.svg';
      case 'ar':
        return 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/sa.svg';
      case 'ko':
        return 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/kr.svg';
      case 'ja':
        return 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/jp.svg';
      case 'vi':
        return 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/vn.svg';
      default:
        return `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/${locale}.svg`;
    }
  };

  const menuHeaderDropdown = (
    <Menu
      className={styles.langmenu}
      onClick={(event: ClickParam) => onSetActiveLanguage(event.key)}
    >
      {languages.map(el => (
        <Menu.Item
          key={el}
          className={`language-dropdown ${el === activeLanguage ? 'isActive' : ''}`}
        >
          {el.toUpperCase()}
          {/* <img width="12" src={getFlagUrl(el)} alt="some" /> */}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <HeaderDropdown trigger={['click']} overlay={menuHeaderDropdown} className={props.className}>
      <span className={`${styles.action} ${styles.langSelectValue}`}>
        {activeLanguage.toUpperCase()}
        <img width="17" src={getFlagUrl(activeLanguage)} alt="flag" />
      </span>
    </HeaderDropdown>
  );
};

const languages = ['en', 'de'];

export default withRouter(AvatarDropdown);
