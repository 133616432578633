/*
 *
 * HomePage
 *
 */
import React, { useState, useEffect, memo } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { Button, InputText, Toggle, Textarea } from '@buffetjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faBars, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { message, Col, Row, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import PageTitle from 'components/PageTitle';
import FileUploader from 'components/FileUploader';
import InviteUsers from 'components/InviteUsers';
import Loading from 'components/Loading';
import LanguagesSelect from 'components/LanguageSelect';
import TagsSelect from 'components/TagsSelect';

import apiClient from 'utils/feathersClient';

import { TagsItem, TransformData } from 'types/common';
import { GroupsItem } from 'types/pages/groups';
import { UsersItem } from 'types/pages/users';

const GroupsForm: React.FC<{}> = () => {
  const history = useHistory();
  const {
    params: { id: groupId },
  } = useRouteMatch();
  const [t] = useTranslation();

  const [group, setGroup] = useState<Partial<GroupsItem>>({});
  const [inviteUsers, setInviteUsers] = useState<number[]>([]);
  const [removeMembers, setRemoveMembers] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>('en');

  const isEdit = groupId ? true : false;

  const fetchData = async () => {
    setIsLoading(true);
    const result = await apiClient.service('groups').get(groupId, {
      query: {
        $getAllLang: true,
      },
    });

    setGroup({
      ...result,
      invitedUsers: result.invitedUsers || [],
      removeMembers: [],
    });
    setIsLoading(false);
  };

  const transformData: TransformData<GroupsItem> = values => {
    return {
      ...values,
      inviteUsers,
      removeMembers,
      groupTags: undefined,
      tags: values.groupTags ? values.groupTags.map((e: TagsItem) => e.title) : [],
    };
  };

  const onSave = async (values: Partial<GroupsItem>) => {
    try {
      await apiClient.service('groups').patch(groupId, transformData(values));
      message.success('Updated successfully!');
      history.push('/groups');
    } catch (e) {
      message.error(`Error while updating group! ${e}`);
    }
  };

  const onAdd = async (values: Partial<GroupsItem>) => {
    try {
      await apiClient.service('groups').create(transformData(values));
      message.success('Added successfully!');
      history.push('/groups');
    } catch (e) {
      message.error(`Error while adding group! ${e}`);
    }
  };

  const onDelete = async () => {
    try {
      await apiClient.service('groups').remove(groupId);
      message.info('Deleted successfully!');
      history.push('/groups');
    } catch (e) {
      message.error(`Error while deleting group! ${e}`);
    }
  };

  const openDeletePopup = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: onDelete,
    });
  };

  useEffect(() => {
    if (isEdit) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageTitle title={t('HomePage.helmet.title')} />

      <div className="back-button" onClick={() => history.push('/groups')}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>

      <Form
        onSubmit={isEdit ? onSave : onAdd}
        initialValues={group}
        render={({ handleSubmit, submitting, pristine, form: { reset } }) => (
          <div>
            <Row justify="space-between">
              {isEdit ? (
                <h1 className="align-center">
                  Group №{groupId}
                  <Button
                    label="Posts"
                    className="posts-button"
                    color="secondary"
                    icon={<FontAwesomeIcon icon={faBars} />}
                    onClick={() => history.push(`/groups/${groupId}/posts`)}
                  />
                </h1>
              ) : (
                <h1>Add Group</h1>
              )}
              <div className="align-center">
                <LanguagesSelect
                  className="mr25"
                  initialValue={language}
                  onSelect={value => setLanguage(value.name)}
                />
                {isEdit && (
                  <Button
                    color="delete"
                    label="Delete"
                    className="mr15"
                    icon={<FontAwesomeIcon icon={faTrashAlt} />}
                    onClick={() => openDeletePopup()}
                  />
                )}
                <Button className="mr15" color="cancel" label="Reset" onClick={reset} />
                {isEdit ? (
                  <Button
                    color="primary"
                    label="Save"
                    disabled={submitting || (pristine && !inviteUsers.length)}
                    onClick={handleSubmit}
                  />
                ) : (
                  <Button
                    color="primary"
                    label="Add"
                    disabled={submitting || (pristine && !inviteUsers.length)}
                    onClick={handleSubmit}
                  />
                )}
              </div>
            </Row>

            <Row className="mt40">
              {isEdit && isLoading ? (
                <Loading style={{ minHeight: '400px' }} />
              ) : (
                <form className="form-container">
                  <Row>
                    <Col span={8}>
                      <Field name={`${language}.name`} validate={singleValidation}>
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="name">Name</label>
                            <InputText
                              {...input}
                              className={
                                (meta.error || meta.submitError) && meta.touched
                                  ? 'invalid-field'
                                  : ''
                              }
                              id="name"
                              name="input"
                              type="text"
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="groupTags">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="groupTags">groupTags</label>
                            <TagsSelect {...input} />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>

                    <Col span={8}>
                      <Field name={`${language}.description`}>
                        {({ input, meta }) => (
                          <div className="form-item" style={{ marginBottom: '43px' }}>
                            <label htmlFor="description">Description</label>
                            <Textarea
                              {...input}
                              style={{ height: '162px' }}
                              id="description"
                              name="input"
                              type="text"
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="isPrivate">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="isPrivate">Private</label>
                            <Toggle
                              {...input}
                              onChange={input.onChange}
                              value={input.value || false}
                              id="isPrivate"
                              name="toggle"
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>

                    <Col span={8} className="photo-wrapper">
                      <Field name="photoId">
                        {({ input }) => (
                          <FileUploader
                            logoId={input.value || 0}
                            accept="image/jpeg, image/png"
                            onFileLoaded={input.onChange}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                </form>
              )}
            </Row>
          </div>
        )}
      />

      <div className="row">
        <InviteUsers
          invitedUsers={group.invitedUsers}
          usersIds={group.usersIds}
          type="group"
          typeId={groupId}
          onInviteChange={(users: UsersItem[]) => setInviteUsers(users.map((e: UsersItem) => e.id))}
          onRemoveChange={(users: UsersItem[]) =>
            setRemoveMembers(users.map((e: UsersItem) => e.id))
          }
        />
      </div>
    </>
  );
};

const singleValidation = (value: string | number): string => {
  let error = '';
  if (!value || (typeof value === 'string' && !value.trim())) {
    error = 'Required field!';
  }
  return error;
};

export default memo(GroupsForm);
