import rootReducer from './reducers';

import { createStore, applyMiddleware, compose } from 'redux';
// import logger from 'redux-logger';
// @ts-ignore
import thunk from 'redux-thunk';
// import thunk from 'redux-thunk';

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  const middlewares: any[] = [thunk];
  // if (process.env.NODE_ENV !== 'production') {
  //   middlewares.push(logger);
  // }

  return createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));
};

export default configureStore;
