import React, { useState } from 'react';
import { message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import AddComment from './AddComment';

import { toDateFormat } from 'utils';
import apiClient from 'utils/feathersClient';

import { CommentsItem, CategoryType } from 'types/components/comments';

type SingleCommentProps = {
  comment: CommentsItem;
  isTypeRecord?: boolean;
  isReply?: boolean;
  categoryType?: CategoryType;
  categoryId?: number;
  onDeleteComment?: (commentId: number, parentCommentId?: number) => void;
  parentCommentId?: number;
};

const Comment: React.FC<SingleCommentProps> = props => {
  const {
    comment,
    categoryId,
    categoryType,
    isReply,
    isTypeRecord,
    onDeleteComment,
    parentCommentId,
  } = props;

  const [isRepliesVisible, setIsRepliesVisible] = useState<boolean>(false);
  const [isAddCommentVisible, setIsAddCommentVisible] = useState<boolean>(false);
  // const [visibleComments, setVisibleComments] = useState([]);

  const whenCommentAdded = async () => {
    if (!isRepliesVisible) {
      setIsRepliesVisible(true);
    }
  };

  const deleteComment = async () => {
    try {
      const service: string = isTypeRecord ? 'content/record-comments' : 'comments';

      await apiClient.service(service).remove(comment.id, { query: { $joins: true } });
      onDeleteComment(comment.id, parentCommentId);
    } catch (e) {
      message.error(`Error while deleting! ${e}`);
    }
  };

  const openDeletePopup = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: deleteComment,
    });
  };

  return (
    <div className="comments__container">
      <div className="comments__item">
        <div className="comments__item-avatar">
          {comment.user && comment.user.userPhoto ? (
            <img src={comment.user.userPhoto.path} alt="avatar" />
          ) : (
            <img src={require('../../assets/images/man.png')} alt="ava" />
          )}
        </div>

        <div className="comments__item-content">
          <div className="comments__item-header">
            <h3>
              {(comment.user && [comment.user.firstName, ' ', comment.user.lastName]) ||
                'Bownce Team'}{' '}
              &bull; {toDateFormat(comment.createdAt)}
            </h3>
            {isTypeRecord && comment.entity && (
              <span>
                {comment.type[0].toUpperCase() + comment.type.slice(1)} {comment.entityId} -{' '}
                {comment.entity.title}
              </span>
            )}
          </div>
          <div className="comments__item-text">{comment.text || '-'}</div>
          <div className="comments__item-actions">
            <div>
              {!isReply && (
                <>
                  <span
                    className="comments__item-actions_buttons mr15"
                    onClick={() => setIsAddCommentVisible(!isAddCommentVisible)}
                  >
                    Reply
                  </span>
                  {comment.replies && comment.replies.length ? (
                    <span
                      className="comments__item-actions_buttons mr15"
                      onClick={() => setIsRepliesVisible(!isRepliesVisible)}
                    >
                      Replies({comment.replies.length})
                    </span>
                  ) : (
                    <span className="mr15">Replies(0)</span>
                  )}
                </>
              )}
              <span className="comments__item-actions_buttons-delete" onClick={openDeletePopup}>
                Delete
              </span>
            </div>
            {/* <div className="comments__item-like">
              <span>{comment.likesCount}</span>
              <img src={require('../../../../assets/icons/heart-red.png')} />
            </div> */}
          </div>
        </div>
      </div>

      {comment.replies && comment.replies.length && isRepliesVisible ? (
        <div className="comments__reply">
          {comment.replies.map(reply => (
            <Comment
              key={reply.id}
              comment={reply}
              isReply
              isTypeRecord={isTypeRecord}
              parentCommentId={comment.id}
              onDeleteComment={onDeleteComment}
            />
          ))}
        </div>
      ) : null}

      <div className="comments__reply">
        {isAddCommentVisible && (
          <AddComment
            categoryType={categoryType}
            categoryId={categoryId}
            comment={comment}
            whenCommentAdded={whenCommentAdded}
            isTypeRecord={isTypeRecord}
          />
        )}
      </div>
    </div>
  );
};

Comment.defaultProps = {
  isReply: false,
  isTypeRecord: false,
  categoryType: null,
  categoryId: null,
  parentCommentId: null,
  onDeleteComment: () => {},
};

export default Comment;
