/*
 *
 * HomePage
 *
 */
import React, { useState, useEffect, memo } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Button, InputText, InputNumber, Toggle, Select } from '@buffetjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { message, Modal, Row, Col } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import PageTitle from 'components/PageTitle';
import Wysiwyg from 'components/_strapi/Wysiwyg';
import Loading from 'components/Loading';
import LanguagesSelect from 'components/LanguageSelect';

import apiClient from 'utils/feathersClient';

import { QuizzesItem } from 'types/pages/quizzes';

import './form.css';
import { ValidationErrorsObject, TransformData } from 'types/common';

const QuizzesForm: React.FC<{}> = () => {
  const history = useHistory();
  const {
    params: { id: quizId },
  } = useRouteMatch();
  const [t] = useTranslation();

  const [quiz, setQuiz] = useState<Partial<QuizzesItem>>({ answers: [], type: 'byActiveTime' });
  // const [initialQuizValues, setInitialQuizValues] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>('en');

  useEffect(() => {
    if (isEdit) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  const isEdit = quizId ? true : false;

  const fetchData = async () => {
    setIsLoading(true);
    const result: QuizzesItem = await apiClient
      .service('content/quizzes')
      .get(quizId, { query: { $getAllLang: true } });

    result.type = result.type || 'byActiveTime';

    setQuiz(result);
    setIsLoading(false);
  };

  const transformData: TransformData<QuizzesItem> = values => {
    return {
      ...values,
      // answers: values.answers.map(answer => ({ ...answer, title: answer.title.trim() })),
    };
  };

  const onSave = async (values: Partial<QuizzesItem>) => {
    try {
      await apiClient.service('content/quizzes').patch(quizId, transformData(values));
      message.success('Updated successfully!');
      history.push('/quizzes');
    } catch (e) {
      message.error(`Error while updating quiz! ${e}`);
    }
  };

  const onAdd = async (values: Partial<QuizzesItem>) => {
    try {
      await apiClient.service('content/quizzes').create(transformData(values));
      message.success('Added successfully!');
      history.push('/quizzes');
    } catch (e) {
      message.error(`Error while adding quiz! ${e}`);
    }
  };

  const onDelete = async () => {
    try {
      await apiClient.service('content/quizzes').create(quizId);
      history.push('/quizzes');
    } catch (e) {
      message.error(`Error while deleting quiz! ${e}`);
    }
  };

  const openDeletePopup = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: onDelete,
    });
  };

  useEffect(() => {
    if (isEdit) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageTitle title={t('HomePage.helmet.title')} />

      <div className="back-button" onClick={() => history.push('/quizzes')}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>

      <Form
        onSubmit={isEdit ? onSave : onAdd}
        mutators={{
          // potentially other mutators could be merged here
          ...arrayMutators,
        }}
        validate={validate}
        initialValues={quiz}
        render={({
          handleSubmit,
          pristine,
          submitting,
          values,
          form: {
            reset,
            mutators: { push },
          },
        }) => (
          <div>
            <Row justify="space-between">
              {isEdit ? <h1>Quiz №{quizId}</h1> : <h1>Add Quiz</h1>}
              <div className="align-center">
                <LanguagesSelect
                  className="mr25"
                  initialValue={language}
                  onSelect={value => setLanguage(value.name)}
                />
                {isEdit && (
                  <Button
                    color="delete"
                    label="Delete"
                    className="mr15"
                    icon={<FontAwesomeIcon icon={faTrashAlt} />}
                    onClick={openDeletePopup}
                  />
                )}
                <Button className="mr15" color="cancel" label="Reset" onClick={reset} />
                {isEdit ? (
                  <Button
                    color="primary"
                    label="Save"
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                  />
                ) : (
                  <Button
                    color="primary"
                    label="Add"
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                  />
                )}
              </div>
            </Row>

            <Row className="mt40">
              {isEdit && isLoading ? (
                <Loading style={{ minHeight: '280px' }} />
              ) : (
                <form className="form-container">
                  <Row>
                    <Col span={10}>
                      <Field name={`${language}.title`} validate={singleValidation}>
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="title">Title</label>
                            <InputText
                              {...input}
                              id="title"
                              className={
                                (meta.error || meta.submitError) && meta.touched
                                  ? 'invalid-field'
                                  : ''
                              }
                              name="input"
                              type="text"
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>

                      <Field name="type">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="type">Notifications type</label>
                            <Select
                              {...input}
                              id="type"
                              name="select"
                              options={notificationsOptions}
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>

                      {values.type === 'byTrainingsCount' ? (
                        <Field name="trainingsCount" validate={singleValidation}>
                          {({ input, meta }) => (
                            <div className="form-item">
                              <label htmlFor="trainingsCount">Trainings count</label>
                              <InputNumber
                                {...input}
                                id="trainingsCount"
                                className={
                                  (meta.error || meta.submitError) && meta.touched
                                    ? 'invalid-field'
                                    : ''
                                }
                                name="input"
                                type="number"
                              />
                              {(meta.error || meta.submitError) && meta.touched && (
                                <span className="error-message">{meta.error}</span>
                              )}
                            </div>
                          )}
                        </Field>
                      ) : values.type === 'byInactivity' ? (
                        <Field name="inactivityDays" validate={singleValidation}>
                          {({ input, meta }) => (
                            <div className="form-item">
                              <label htmlFor="inactivityDays">Inactivity days</label>
                              <InputNumber
                                {...input}
                                id="inactivityDays"
                                className={
                                  (meta.error || meta.submitError) && meta.touched
                                    ? 'invalid-field'
                                    : ''
                                }
                                name="input"
                                type="number"
                              />
                              {(meta.error || meta.submitError) && meta.touched && (
                                <span className="error-message">{meta.error}</span>
                              )}
                            </div>
                          )}
                        </Field>
                      ) : (
                        <Field name="dateFromActive" validate={singleValidation}>
                          {({ input, meta }) => (
                            <div className="form-item">
                              <label htmlFor="dateFromActive">Date from active</label>
                              <DatePicker
                                className={
                                  (meta.error || meta.submitError) && meta.modified
                                    ? 'invalid-date'
                                    : ''
                                }
                                value={input.value ? new Date(input.value) : null}
                                onChange={input.onChange}
                              />
                              {(meta.error || meta.submitError) && meta.modified && (
                                <span className="error-message">{meta.error}</span>
                              )}
                            </div>
                          )}
                        </Field>
                      )}
                    </Col>
                  </Row>

                  <Row className="mt25">
                    <Col span={24} style={{ minWidth: '600px' }}>
                      <Field name="isActive">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="isActive">Active</label>
                            <div className="isactive-field">
                              <Toggle
                                {...input}
                                onChange={input.onChange}
                                value={input.value || false}
                                id="isActive"
                                name="toggle"
                              />
                              {(meta.error || meta.submitError) && values.answers.length ? (
                                <span className="error-message">{meta.error}</span>
                              ) : null}
                            </div>
                          </div>
                        )}
                      </Field>

                      <FieldArray name="answers">
                        {({ fields }) => (
                          <div>
                            {fields.map((e, i) => (
                              <div className="answer-row" key={i}>
                                <label htmlFor={`answer-${i}`}>Answer {i + 1}</label>
                                <Field name={`${e}.${language}.title`} validate={singleValidation}>
                                  {({ input, meta }) => (
                                    <div className="answers-name-field">
                                      <InputText
                                        {...input}
                                        id={`answer-${i}`}
                                        className={
                                          (meta.error || meta.submitError) && meta.touched
                                            ? 'invalid-field'
                                            : ''
                                        }
                                        name="input"
                                        type="text"
                                      />
                                      {(meta.error || meta.submitError) && meta.touched && (
                                        <span className="error-message">{meta.error}</span>
                                      )}
                                    </div>
                                  )}
                                </Field>

                                <Field name={`${e}.answersCount`}>
                                  {({ input, meta }) => <p>Votes - {input.value}</p>}
                                </Field>

                                <Field name={`${e}.isCorrect`}>
                                  {({ input }) => (
                                    <div>
                                      <Toggle
                                        {...input}
                                        name="toggle"
                                        onChange={input.onChange}
                                        value={input.value || false}
                                        id={`isCorrect-${i}`}
                                      />
                                    </div>
                                  )}
                                </Field>
                                <Button
                                  color="delete"
                                  icon={<FontAwesomeIcon icon={faTrashAlt} />}
                                  onClick={() => fields.remove(i)}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </FieldArray>

                      <div className="buttons">
                        <Button
                          color="primary"
                          icon={<FontAwesomeIcon icon={faPlus} />}
                          onClick={() => push('answers', { isCorrect: false, answersCount: 0 })}
                        >
                          Add Answer
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt25">
                    <Col span={24}>
                      <Field name={`${language}.description`}>
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label>Description</label>
                            <Wysiwyg
                              {...input}
                              className={
                                (meta.error || meta.submitError) && meta.touched
                                  ? 'invalid-field'
                                  : ''
                              }
                              name="quizzes-wywiwyg"
                              lang={language}
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>
                  </Row>
                </form>
              )}
            </Row>
          </div>
        )}
      />
    </>
  );
};

const notificationsOptions = [
  {
    label: 'By active time',
    value: 'byActiveTime',
  },
  {
    label: 'By trainings count',
    value: 'byTrainingsCount',
  },
  {
    label: 'By inactivity',
    value: 'byInactivity',
  },
];

const validate = (values: Partial<QuizzesItem>) => {
  const errors: ValidationErrorsObject<QuizzesItem> = {};
  if (values.answers && Array.isArray(values.answers) && values.answers.length < 2) {
    errors.isActive = 'There should be at least 2 answers';
  }

  return errors;
};

const singleValidation = (value: string | number) => {
  let error = '';
  if (!value || (typeof value === 'string' && !value.trim())) {
    error = 'Required!';
  }
  return error;
};

export default memo(QuizzesForm);
