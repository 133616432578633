import React, { useState, useEffect } from 'react';
import Pagination from 'react-paginate';
import { Table, Select, Button } from '@buffetjs/core';
import { Row } from 'antd';

import Loading from 'components/Loading';

import apiClient from 'utils/feathersClient';

import { TablePagination, ApiListData, TableHeaders, CustomRow } from 'types/common';
import { UsersItem } from 'types/pages/users';

import './index.less';

type InviteUsersProps = {
  type: 'group' | 'challenge' | '';
  typeId: string;
  invitedUsers: number[];
  usersIds: number[];
  onInviteChange?: (users: UsersItem[]) => void;
  onRemoveChange?: (users: UsersItem[]) => void;
};

const InviteUsers: React.FC<InviteUsersProps> = props => {
  const { type, typeId, onInviteChange, onRemoveChange, invitedUsers, usersIds } = props;

  const [users, setUsers] = useState<UsersItem[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<UsersItem[]>([]);
  // eslint-disable-next-line
  const [removeMembers, setRemoveMembers] = useState<UsersItem[]>([]);
  const [search, setSearch] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<TablePagination>({ total: 100, limit: 10, page: 1 });

  const fetchData = async (paginationObj: Partial<TablePagination> = {}) => {
    setIsLoading(true);
    if (isLoading) {
      return false;
    }
    const page = paginationObj.page || pagination.page;
    const limit = paginationObj.limit || pagination.limit;
    const query: any = {
      // $select: ['id', 'email', 'firstName', 'lastName', 'description', 'photoId', 'memberStatus'],
      $limit: limit,
      $skip: page - 1 > 0 ? (page - 1) * limit : 0,
      role: 'client',
    };
    if (type && typeId) {
      query[`$sort${type.slice(0, 1).toUpperCase()}${type.slice(1)}Id`] = typeId;
    }
    if (search) {
      query.$search = search;
    }
    const result: ApiListData<UsersItem> = await apiClient.service('users').find({ query });

    setUsers(result.data);

    setPagination({
      limit,
      page,
      total: result.total,
    });
    setIsLoading(false);
  };

  const onSearch = (e: any) => {
    if (e.key !== 'Enter') {
      return false;
    }

    fetchData({ page: 1 });
  };

  const onSelectUser = (user: UsersItem) => {
    if (!user || user.memberStatus === 2) {
      return false;
    }

    const status = !selectedUsers.some(e => e.id === user.id);
    if (status) {
      if (selectedUsers.map(e => e.id).includes(user.id)) {
        return false;
      }
      setSelectedUsers([...selectedUsers, user]);
    } else {
      setSelectedUsers(selectedUsers.filter(e => e.id !== user.id));
    }
  };

  // const onRemove = async (user: UsersItem) => {
  //   if (!user) {
  //     return false;
  //   }

  //   const status = !removeMembers.some(e => e.id === user.id);
  //   if (status) {
  //     if (removeMembers.map(e => e.id).includes(user.id)) {
  //       return false;
  //     }
  //     setRemoveMembers([...removeMembers, user]);
  //   } else {
  //     setRemoveMembers(removeMembers.filter(e => e.id !== user.id));
  //   }
  // };

  const onLimitChange = (limit: number) => {
    fetchData({ page: 1, limit });
  };

  const onPageChange = (selected: number) => {
    fetchData({ page: selected + 1 });
  };

  const customRow = ({ row }: CustomRow<UsersItem>) => {
    const { userPhoto, id, email, firstName, lastName, country } = row;

    const stopPropagation = (e: React.MouseEvent<any>) => {
      e.preventDefault();
    };

    const isMember = usersIds && usersIds.some((e: number) => e === row.id);
    const isInvited = invitedUsers && invitedUsers.some((e: number) => e === row.id);
    const isSelected = selectedUsers && selectedUsers.some(e => e.id === row.id);
    // const isRemove = removeMembers && removeMembers.some(e => e.id === row.id);

    return (
      <tr onClick={stopPropagation}>
        <td>
          {userPhoto && userPhoto.path ? (
            <img className="user-photo" src={userPhoto.path} alt="a" />
          ) : (
            <img className="user-photo" src={require('../../assets/images/man.png')} alt="a" />
          )}
        </td>
        <td>
          <p>{id}</p>
        </td>
        <td>
          <p>{email}</p>
        </td>
        <td>
          <p>{firstName}</p>
        </td>
        <td>
          <p>{lastName}</p>
        </td>
        <td>
          <p>{country ? country.name : ''}</p>
        </td>
        <td>
          {!isMember &&
            (isSelected || isInvited ? (
              <Button
                onClick={() => onSelectUser(row)}
                color="delete"
                label="Invited"
                disabled={isInvited}
              />
            ) : (
              <Button onClick={() => onSelectUser(row)} color="success" label="Invite" />
            ))}
        </td>
        {/* <td onClick={stopPropagation}>
          {isMember &&
            (isRemove ? (
              <Button onClick={() => onRemove(row)} color="secondary" label="Removed" />
            ) : (
              <Button onClick={() => onRemove(row)} color="delete" label="Remove" />
            ))}
        </td> */}
      </tr>
    );
  };

  useEffect(() => {
    onInviteChange(selectedUsers);
    // eslint-disable-next-line
  }, [selectedUsers]);

  useEffect(() => {
    onRemoveChange(removeMembers);
    // eslint-disable-next-line
  }, [removeMembers]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="invite-users">
      <div>
        <Row justify="space-between" align="middle">
          <div>
            <h1>Users</h1>
            <p style={{ fontSize: '1.3rem', color: '#787e8f' }}>
              {users.length} {users.length > 1 ? 'entries' : 'entry'} found
            </p>
          </div>
          <div className="search-users">
            <input
              type="text"
              placeholder="Search for an entry..."
              value={search || ''}
              onChange={({ target: { value } }: any) => setSearch(value)}
              onKeyUp={onSearch}
            />
          </div>
        </Row>

        {/* <div className="row mt40">
          <Button color="secondary" icon={<FontAwesomeIcon icon={faAlignCenter} />} label="Filters" />
        </div> */}

        <Row className="mt40">
          {isLoading ? (
            <Loading style={{ minHeight: '667px' }} />
          ) : (
            <Table headers={headers} customRow={customRow} rows={users} />
          )}
        </Row>

        <Row className="mt40" justify="space-between">
          <Select
            value={pagination.limit}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onLimitChange(+e.target.value)}
            options={paginationOptions}
            name="select"
            className="pagination-select"
          />
          <Pagination
            containerClassName="pagination__container"
            pageCount={Math.ceil(pagination.total / pagination.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            previousLabel={<span>&lt;</span>}
            nextLabel={<span>&gt;</span>}
            onPageChange={({ selected }) => onPageChange(selected)}
          />
        </Row>
      </div>
    </div>
  );
};

const headers: TableHeaders<UsersItem> = [
  {
    name: 'Photo',
    value: 'userPhoto',
  },
  {
    name: 'Id',
    value: 'id',
  },
  {
    name: 'Email',
    value: 'email',
  },
  {
    name: 'First name',
    value: 'firstName',
  },
  {
    name: 'Last name',
    value: 'lastName',
  },
  {
    name: 'Country',
    value: 'country',
  },
  {
    name: 'Invite',
    value: '',
  },
  // {
  //   name: 'member',
  //   value: 'member',
  // },
];

const paginationOptions = [10, 20, 50];

InviteUsers.defaultProps = {
  invitedUsers: [],
  usersIds: [],
  type: '',
  typeId: '',
};

export default InviteUsers;
