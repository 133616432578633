/*
 *
 * HomePage
 *
 */
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import PageTitle from 'components/PageTitle';
import Comments from 'components/Comments';

const HomePage: React.FC<{}> = () => {
  const [t] = useTranslation();

  return (
    <>
      <PageTitle title={t('HomePage.helmet.title')} />

      <div>
        <Comments categoryType="entity" />
      </div>
    </>
  );
};

export default memo(HomePage);
