import React from 'react';
import ReactSelect from 'react-select-async-paginate';

import apiClient from 'utils/feathersClient';

import { SelectLanguageItem } from 'types/common';

import './index.css';

type SelectLanguageProps = {
  initialValue: string;
  onSelect: (value: SelectLanguageItem) => void;
  label?: string;
  placeholder?: string;
  className?: string;
};

const CountrySelect: React.FC<SelectLanguageProps> = props => {
  const { onSelect, initialValue, className } = props;

  const fetchLanguages = async (search: string, prevOptions: any, additional: any) => {
    const limit = 15;
    const page = prevOptions.length / limit;
    if (additional && page * limit >= additional.total) {
      return {
        options: [] as any[],
        hasMore: false,
      };
    }

    const query: any = {
      $limit: limit,
      $skip: page * limit,
    };
    if (search) {
      query.$search = search;
    }
    const result = await apiClient.service('languages').find({ query });

    return {
      options: result.data,
      hasMore: page * limit < result.total,
      additional: {
        total: result.total,
      },
    };
  };

  return (
    <div className={`language-select-wrapper ${className}`}>
      <label htmlFor="language">Language:</label>
      <ReactSelect
        id="language"
        className="language-select"
        classNamePrefix="async-select"
        getOptionLabel={item => (item ? item.name : null)}
        getOptionValue={item => (item ? item.id.toString() : null)}
        maxMenuHeight={230}
        isClearable={false}
        loadOptions={(...args) => fetchLanguages(...args)}
        defaultOptions
        value={{ id: Math.random(), name: initialValue }}
        // @ts-ignore
        onChange={onSelect}
      />
    </div>
  );
};

CountrySelect.defaultProps = {
  initialValue: 'en',
  className: '',
};

export default CountrySelect;
