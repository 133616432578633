/*
 *
 * HomePage
 *
 */
import React, { useState, useEffect, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Pagination from 'react-paginate';
import { Table, Select, Button } from '@buffetjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash, faPlus, faBars } from '@fortawesome/free-solid-svg-icons';
import { message, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import PageTitle from 'components/PageTitle';
import Loading from 'components/Loading';
import CustomPopconfirm from 'components/CustomPopconfirm';

import { toDateFormat } from 'utils';
import apiClient from 'utils/feathersClient';

import { TablePagination, CustomRow, TableHeaders, ApiListData } from 'types/common';
import { ChallengesItem } from 'types/pages/challenges';
import { RootState } from 'store/types';

const HomePage: React.FC<{}> = () => {
  const history = useHistory();
  const [t] = useTranslation();

  const [challenges, setChallenges] = useState<ChallengesItem[]>([]);
  const [search, setSearch] = useState<string>('');
  const [pagination, setPagination] = useState<TablePagination>({ total: 100, limit: 10, page: 1 });
  // const [filters, setFilters] = useState({});
  // const [activeFilters, setActiveFilters] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { user } = useSelector<RootState, RootState['user']>(state => state.user);
  const isPartner = user && user.role === 'partner';

  const fetchData = async (paginationObj: Partial<TablePagination> = {}) => {
    setIsLoading(true);
    if (isLoading) {
      return false;
    }
    const page = paginationObj.page || pagination.page;
    const limit = paginationObj.limit || pagination.limit;
    const query: any = {
      $limit: limit,
      $skip: page - 1 > 0 ? (page - 1) * limit : 0,
      // $getAllLang: true,
      $sort: { id: -1 },
    };
    if (search) {
      query.$search = search;
    }
    const result: ApiListData<ChallengesItem> = await apiClient
      .service('challenges')
      .find({ query });

    setChallenges(result.data);
    setPagination({
      limit,
      page,
      total: result.total,
    });
    setIsLoading(false);
  };

  const onDelete = async (id: number) => {
    try {
      await apiClient.service('challenges').remove(id);
      message.info(`Deleted successfully`);
      fetchData();
    } catch (e) {
      message.error(`Error while deleting group! ${e}`);
    }
  };

  const onSearch = (pressedButton: string) => {
    if (pressedButton === 'Enter') {
      fetchData({ page: 1 });
    }
  };

  const onLimitChange = (limit: number) => {
    fetchData({ page: 1, limit });
  };

  const onPageChange = (selected: number) => {
    fetchData({ page: selected + 1 });
  };

  const customRow = ({ row }: CustomRow<ChallengesItem>) => {
    const {
      id,
      name,
      startDate,
      endDate,
      level,
      winnersCount,
      hitPower,
      type,
      description,
      isPrivate,
    } = row;

    return (
      <tr onClick={() => history.push(`/challenges/${id}`)}>
        <td>
          <p>{id}</p>
        </td>
        <td>
          <p>{name}</p>
        </td>
        <td>
          <p>{toDateFormat(startDate)}</p>
        </td>
        <td>
          <p>{toDateFormat(endDate)}</p>
        </td>
        <td>
          <p>{level}</p>
        </td>
        <td>
          <p>{winnersCount}</p>
        </td>
        <td>
          <p>{hitPower}</p>
        </td>
        <td>
          <p>{type}</p>
        </td>
        <td style={{ maxWidth: '300px' }}>
          <p title={description && description.length > 60 ? description : ''}>
            {description || '-'}
          </p>
        </td>
        <td>
          <p>{isPrivate ? 'Yes' : 'No'}</p>
        </td>
        <td onClick={event => event.stopPropagation()}>
          <p>
            <Button
              label="Posts"
              color="secondary"
              icon={<FontAwesomeIcon icon={faBars} />}
              onClick={() => history.push(`/challenges/${id}/posts`)}
            />
            <FontAwesomeIcon
              className="ml15"
              style={{ transform: 'scale(0.8)' }}
              icon={faPencilAlt}
              onClick={() => history.push(`/challenges/${id}`)}
            />
            <CustomPopconfirm
              title="Are you sure you want to delete this?"
              onConfirm={() => onDelete(id)}
              okText="Yes"
              cancelText="No"
            >
              <FontAwesomeIcon
                className="ml15"
                style={{ transform: 'scale(0.8)' }}
                icon={faTrash}
              />
            </CustomPopconfirm>
          </p>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageTitle title={t('HomePage.helmet.title')} />

      <div>
        <Row justify="space-between" align="middle">
          <div>
            <h1>Challenges</h1>
            <p style={{ fontSize: '1.3rem', color: '#787e8f' }}>
              {challenges.length} {challenges.length > 1 ? 'entries' : 'entry'} found
            </p>
          </div>
          {!isPartner && (
            <Button
              color="primary"
              icon={<FontAwesomeIcon icon={faPlus} />}
              label="Add Challenge"
              onClick={() => history.push(`/challenges/add`)}
            />
          )}
        </Row>

        <div className="search-field">
          <div>
            <input
              type="text"
              placeholder="Search for an entry..."
              value={search}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
              onKeyUp={(e: React.KeyboardEvent) => onSearch(e.key)}
            />
          </div>
          <div className="search-field--tag">
            <div />
            Challenge
          </div>
        </div>

        {/* <div className="row mt40">
          <Button color="secondary" icon={<FontAwesomeIcon icon={faAlignCenter} />} label="Filters" />
        </div> */}

        <div className="row mt40">
          {isLoading ? (
            <Loading style={{ minHeight: '740px' }} />
          ) : (
            <Table headers={headers} rows={challenges} customRow={customRow} />
          )}
        </div>

        <div className="row mt40" style={{ justifyContent: 'space-between' }}>
          <Select
            value={pagination.limit}
            onChange={(e: any) => onLimitChange(e.target.value)}
            options={paginationOptions}
            name="select"
            className="pagination-select"
          />
          <Pagination
            containerClassName="pagination__container"
            pageCount={Math.ceil(pagination.total / pagination.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            previousLabel={<span>&lt;</span>}
            nextLabel={<span>&gt;</span>}
            onPageChange={({ selected }) => onPageChange(selected)}
          />
        </div>
      </div>
    </>
  );
};

const headers: TableHeaders<ChallengesItem> = [
  {
    name: 'Id',
    value: 'id',
    isSortEnabled: true,
  },
  {
    name: 'Name',
    value: 'name',
  },
  {
    name: 'Start Date',
    value: 'startDate',
    isSortEnabled: true,
  },
  {
    name: 'End Date',
    value: 'endDate',
    isSortEnabled: true,
  },
  {
    name: 'Hit Power',
    value: 'hitPower',
    isSortEnabled: true,
  },
  {
    name: 'Level',
    value: 'level',
    isSortEnabled: true,
  },
  {
    name: 'Type',
    value: 'type',
    isSortEnabled: true,
  },
  {
    name: 'Winners Count',
    value: 'winnersCount',
    isSortEnabled: true,
  },
  {
    name: 'Description',
    value: 'description',
  },
  {
    name: 'Private',
    value: 'isPrivate',
    isSortEnabled: true,
  },
  {
    name: '',
    value: '',
  },
];

const paginationOptions = [10, 20, 50];

export default memo(HomePage);
