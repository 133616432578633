import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';

import Routes from 'routes/index';
import configureStore from 'store/configureStore';
import { checkIsUserAuth } from 'store/user/actions';

import './App.css';
import 'assets/styles/index.less';

import './i18n';

const store = configureStore();

function App() {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const checkUser = async () => {
    // @ts-ignore
    await store.dispatch(checkIsUserAuth());
    setIsLoading(false);
  };

  useEffect(() => {
    checkUser();
  }, []);

  return !isLoading ? (
    <Provider store={store}>
      <Routes />
    </Provider>
  ) : null;
}

export default App;
