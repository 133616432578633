/*
 *
 * HomePage
 *
 */
import React, { useState, useEffect, memo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { Button, InputText, InputNumber, Select } from '@buffetjs/core';
import { useTranslation } from 'react-i18next';
import { Row, Col, message, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import PageTitle from 'components/PageTitle';
import Loading from 'components/Loading';
import LanguagesSelect from 'components/LanguageSelect';

import apiClient from 'utils/feathersClient';

import { TransformData } from 'types/common';
import { NotificationsSettings } from 'types/pages/settings';

const NotificationsSettingsForm: React.FC<{}> = () => {
  const history = useHistory();
  const {
    params: { id: notificationsSettingId },
  } = useRouteMatch();
  const [t] = useTranslation();

  const [notificationsSettings, setNotificationsSettings] = useState<NotificationsSettings | {}>(
    {}
  );
  const [language, setLanguage] = useState<string>('en');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isEdit = notificationsSettingId ? true : false;

  const fetchData = async () => {
    setIsLoading(true);
    const result: NotificationsSettings = await apiClient
      .service('notifications-motivational')
      .get(notificationsSettingId, { query: { $getAllLang: true } });

    setNotificationsSettings(result);
    setIsLoading(false);
  };

  const transformData: TransformData<NotificationsSettings> = values => {
    return {
      ...values,
      status: values.type === 'league' ? values.status : null,
      inactivityDays: values.type === 'inactivity' ? values.inactivityDays : null,
    };
  };

  const onSave = async (values: Partial<NotificationsSettings>) => {
    try {
      await apiClient
        .service('notifications-motivational')
        .patch(notificationsSettingId, transformData(values));
      message.success('Successfully updated!');
      history.push('/settings/notifications');
    } catch (e) {
      message.error(`Error while updating settings! ${e}`);
    }
  };

  const onAdd = async (values: Partial<NotificationsSettings>) => {
    try {
      await apiClient.service('notifications-motivational').create(transformData(values));
      message.success('Successfully added!');
      history.push('/settings/notifications');
    } catch (e) {
      message.error(`Error while adding settings! ${e}`);
    }
  };

  const onDelete = async () => {
    try {
      await apiClient.service('notifications-motivational').remove(notificationsSettingId);
      message.info('Deleted successfully!');
      history.push('/charities');
    } catch (e) {
      message.error(`Error while deleting charity! ${e}`);
    }
  };

  const openDeletePopup = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: onDelete,
    });
  };

  useEffect(() => {
    if (isEdit) {
      fetchData();
    } else {
      setNotificationsSettings({
        type: 'league',
        status: 'advance',
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageTitle title={t('HomePage.helmet.title')} />

      <div className="back-button" onClick={() => history.push('/settings/notifications')}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>

      <Form
        onSubmit={isEdit ? onSave : onAdd}
        initialValues={notificationsSettings}
        render={({ handleSubmit, pristine, submitting, values, form: { reset } }) => (
          <div>
            <Row justify="space-between" align="middle">
              <h1>
                {isEdit
                  ? `Notifications setting №${notificationsSettingId}`
                  : 'Add notifications setting'}
              </h1>
              <div className="align-center">
                <LanguagesSelect
                  className="mr25"
                  initialValue={language}
                  onSelect={value => setLanguage(value.name)}
                />
                {/* <Button className="mr15" color="cancel" label="Reset" onClick={reset} />
                <Button
                  color="primary"
                  label="Save"
                  disabled={pristine || submitting}
                  onClick={handleSubmit}
                /> */}
                {isEdit && (
                  <Button
                    color="delete"
                    label="Delete"
                    className="mr15"
                    icon={<FontAwesomeIcon icon={faTrashAlt} />}
                    onClick={openDeletePopup}
                  />
                )}
                <Button className="mr15" color="cancel" label="Reset" onClick={reset} />
                {isEdit ? (
                  <Button
                    color="primary"
                    label="Save"
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                  />
                ) : (
                  <Button
                    color="primary"
                    label="Add"
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                  />
                )}
              </div>
            </Row>

            <Row className="mt40">
              {isLoading ? (
                <Loading style={{ minHeight: '280px' }} />
              ) : (
                <form className="form-container">
                  <Row>
                    <Col span={10}>
                      <Field name={`${language}.title`} validate={validateTitle}>
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="workoutsTitle">Title</label>
                            <InputText
                              {...input}
                              id="workoutsTitle"
                              className={
                                (meta.error || meta.submitError) && meta.touched
                                  ? 'invalid-field'
                                  : ''
                              }
                              name="input"
                              type="text"
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                            <div className="mt15">
                              {typeTitles[values.type] &&
                                typeTitles[values.type].map(
                                  el =>
                                    !input.value.includes(`[${el}]`) && (
                                      <Button
                                        key={el}
                                        className="mr10"
                                        onClick={() => input.onChange(input.value + `[${el}]`)}
                                      >
                                        {el
                                          .split(/(?=[A-Z])/)
                                          .map(word => word[0].toUpperCase() + word.slice(1))
                                          .join(' ')}
                                      </Button>
                                    )
                                )}
                            </div>
                          </div>
                        )}
                      </Field>

                      <Field name="type">
                        {({ input }) => (
                          <div className="form-item">
                            <label htmlFor="type">Type</label>
                            <Select id="type" {...input} options={typeOptions} disabled={isEdit} />
                          </div>
                        )}
                      </Field>

                      {values.type === 'league' && (
                        <Field name="status">
                          {({ input }) => (
                            <div className="form-item">
                              <label htmlFor="status">Status</label>
                              <Select id="status" {...input} options={statusOptions} />
                            </div>
                          )}
                        </Field>
                      )}

                      {values.type === 'inactivity' && (
                        <Field name="inactivityDays">
                          {({ input, meta }) => (
                            <div className="form-item">
                              <label htmlFor="inactivityDays">After inactivity</label>
                              <InputNumber
                                {...input}
                                id="inactivityDays"
                                className={
                                  (meta.error || meta.submitError) && meta.touched
                                    ? 'invalid-field'
                                    : ''
                                }
                                name="input"
                                type="number"
                              />
                            </div>
                          )}
                        </Field>
                      )}
                    </Col>
                  </Row>
                </form>
              )}
            </Row>
          </div>
        )}
      />
    </>
  );
};

const typeTitles = {
  league: ['firstName', 'lastName'],
  inactivity: ['firstName', 'lastName', 'inactivityDays'],
  'inactivity-league': ['firstName', 'lastName'],
  session: ['firstName', 'lastName', 'hits', 'hitPower', 'minutesSpent', 'points'],
};

const typeOptions = [
  {
    label: 'League',
    value: 'league',
  },
  {
    label: 'Inactivity',
    value: 'inactivity',
  },
  {
    label: 'Inactivity League',
    value: 'inactivity-league',
  },
  {
    label: 'Session',
    value: 'session',
  },
];

const statusOptions = [
  {
    label: 'Advance',
    value: 'advance',
  },
  {
    label: 'Stay',
    value: 'stay',
  },
  {
    label: 'Downgrade',
    value: 'downgrade',
  },
];

const validateTitle = (title: string, { type }: Partial<NotificationsSettings>): string => {
  if (!title) return 'Required field!';
  if (!typeTitles[type]) return null;

  const allTypes = Object.values(typeTitles)
    .reduce((acc, curr) => acc.concat(curr), [])
    .filter((el, index, arr) => arr.indexOf(el) === index);
  const filtered = allTypes.filter(el => !typeTitles[type].includes(el)).map(el => `\\[${el}\\]`);
  let error: string = '';

  if (title && title.match(new RegExp(filtered.join('|'), 'g'))) {
    error = "There's options that current notifications type doesn't have";
  }
  return error;
};

export default memo(NotificationsSettingsForm);
