import React from 'react';
import { useSelector } from 'react-redux';

import {
  Route,
  Redirect,
  // RouteComponentProps,
} from 'react-router-dom';

import { RootState } from '../store/types';
import { AppRouteProps } from 'types/common';

const AppRoute: React.FC<AppRouteProps> = props => {
  const { component: Component, layout: Layout, isProtected, location, roles, ...rest } = props;

  const { isAuth, user } = useSelector<RootState, RootState['user']>(state => state.user);

  const renderRouter = () => (
    <Route
      {...rest}
      render={routeProps => (
        <Layout>
          <Component {...routeProps} />
        </Layout>
      )}
    />
  );

  if (isProtected) {
    return isAuth ? (
      roles && roles.includes(user.role) ? (
        renderRouter()
      ) : (
        <Redirect
          to={{
            pathname: '/exception/404',
          }}
        />
      )
    ) : (
      <Redirect
        to={{
          pathname: '/user/login',
        }}
      />
    );
  }

  return renderRouter();
};

export default AppRoute;
