/*
 *
 * HomePage
 *
 */
import React, { useState, useEffect, memo } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { Button, InputText, Select } from '@buffetjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { message, Col, Row, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import DateTimePicker from 'react-datetime-picker';
import { useTranslation } from 'react-i18next';

import PageTitle from 'components/PageTitle';
import Wysiwyg from 'components/_strapi/Wysiwyg';
import Loading from 'components/Loading';
import LanguagesSelect from 'components/LanguageSelect';
import FileUploader from 'components/FileUploader';

import apiClient from 'utils/feathersClient';

import { NewsItem } from 'types/pages/news';
import { ValidationErrorsObject } from 'types/common';

const NewsForm: React.FC<{}> = () => {
  const history = useHistory();
  const {
    params: { id: newsId },
  } = useRouteMatch();
  const [t] = useTranslation();

  const [news, setNews] = useState<NewsItem | {}>({ type: 'sport' });
  const [initPubDate, setInitPubDate] = useState<NewsItem['pubDate']>();
  // const [initialPageValues, setInitialPageValues] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>('en');

  const isEdit = newsId ? true : false;

  const fetchData = async () => {
    setIsLoading(true);
    const result: NewsItem = await apiClient
      .service('content/news')
      .get(newsId, { query: { $getAllLang: true } });

    setNews(result);
    setInitPubDate(result.pubDate);
    setIsLoading(false);
  };

  const onSave = async (values: Partial<NewsItem>) => {
    try {
      await apiClient.service('content/news').patch(newsId, values);
      message.success('Updated successfully!');
      history.push('/news');
    } catch (e) {
      message.error(`Error while updating group! ${e}`);
    }
  };

  const onAdd = async (values: Partial<NewsItem>) => {
    try {
      await apiClient.service('content/news').create(values);
      message.success('Added successfully!');
      history.push('/news');
    } catch (e) {
      message.error(`Error while adding group! ${e}`);
    }
  };

  const onDelete = async () => {
    try {
      await apiClient.service('content/news').remove(newsId);
      message.info('Deleted successfully!');
      history.push('/news');
    } catch (e) {
      message.error(`Error while deleting news! ${e}`);
    }
  };

  const openDeletePopup = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: onDelete,
    });
  };

  useEffect(() => {
    if (isEdit) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageTitle title={t('HomePage.helmet.title')} />

      <div className="back-button" onClick={() => history.push('/news')}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>

      <Form
        onSubmit={isEdit ? onSave : onAdd}
        validate={validate}
        initialValues={news}
        render={({ handleSubmit, pristine, submitting, form: { reset } }) => (
          <div>
            <Row justify="space-between">
              {isEdit ? <h1>News №{newsId}</h1> : <h1>Add News</h1>}
              <div className="align-center">
                <LanguagesSelect
                  className="mr25"
                  initialValue={language}
                  onSelect={value => setLanguage(value.name)}
                />
                {isEdit && (
                  <Button
                    color="delete"
                    label="Delete"
                    className="mr15"
                    icon={<FontAwesomeIcon icon={faTrashAlt} />}
                    onClick={openDeletePopup}
                  />
                )}
                <Button className="mr15" color="cancel" label="Reset" onClick={reset} />
                {isEdit ? (
                  <Button
                    color="primary"
                    label="Save"
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                  />
                ) : (
                  <Button
                    color="primary"
                    label="Add"
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                  />
                )}
              </div>
            </Row>

            <Row className="mt40">
              {isEdit && isLoading ? (
                <Loading style={{ minHeight: '280px' }} />
              ) : (
                <form className="form-container">
                  <Row>
                    <Col span={16}>
                      <Row className="nomargin-row">
                        <Col span={12}>
                          <Field name={`${language}.title`} validate={singleValidation}>
                            {({ input, meta }) => (
                              <div className="form-item">
                                <label htmlFor="title">Title</label>
                                <InputText
                                  {...input}
                                  id="title"
                                  className={
                                    (meta.error || meta.submitError) && meta.touched
                                      ? 'invalid-field'
                                      : ''
                                  }
                                  name="input"
                                  type="text"
                                />
                                {(meta.error || meta.submitError) && meta.touched && (
                                  <span className="error-message">{meta.error}</span>
                                )}
                              </div>
                            )}
                          </Field>
                          <Field name="pubDate">
                            {({ input, meta }) => (
                              <div className="form-item">
                                <label htmlFor="pubDate">Pub Date</label>
                                <DateTimePicker
                                  id="pubDate"
                                  className={
                                    (meta.error || meta.submitError) && meta.touched
                                      ? 'invalid-date'
                                      : ''
                                  }
                                  value={input.value ? new Date(input.value) : ''}
                                  onChange={input.onChange}
                                  disabled={initPubDate && new Date() > new Date(initPubDate)}
                                />
                                {(meta.error || meta.submitError) && meta.touched && (
                                  <span className="error-message">{meta.error}</span>
                                )}
                              </div>
                            )}
                          </Field>
                        </Col>

                        <Col span={12}>
                          <Field name="type">
                            {({ input, meta }) => (
                              <div className="form-item">
                                <label htmlFor="type">Type</label>
                                <Select {...input} id="type" name="select" options={typeOptions} />
                                {(meta.error || meta.submitError) && meta.touched && (
                                  <span className="error-message">{meta.error}</span>
                                )}
                              </div>
                            )}
                          </Field>
                          <Field name="videoLink">
                            {({ input, meta }) => (
                              <div className="form-item">
                                <label htmlFor="videoLink">Video (YouTube ID)</label>
                                <InputText
                                  {...input}
                                  id="videoLink"
                                  className={
                                    (meta.error || meta.submitError) && meta.touched
                                      ? 'invalid-field'
                                      : ''
                                  }
                                  name="input"
                                  type="text"
                                />
                                {(meta.error || meta.submitError) && meta.touched && (
                                  <span className="error-message">{meta.error}</span>
                                )}
                              </div>
                            )}
                          </Field>
                        </Col>
                      </Row>

                      <Row className="mt25 nomargin-row">
                        <Col span={24}>
                          <Field name={`${language}.content`}>
                            {({ input, meta }) => (
                              <div className="form-item">
                                <label>Content</label>
                                <Wysiwyg
                                  {...input}
                                  className={
                                    (meta.error || meta.submitError) && meta.touched
                                      ? 'invalid-field'
                                      : ''
                                  }
                                  name="news-wywiwyg"
                                  lang={language}
                                />
                                {(meta.error || meta.submitError) && meta.touched && (
                                  <span className="error-message">{meta.error}</span>
                                )}
                              </div>
                            )}
                          </Field>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={8} className="photo-wrapper">
                      <Field name="imageId">
                        {({ input }) => (
                          <FileUploader
                            logoId={input.value || 0}
                            accept="image/jpeg, image/png"
                            onFileLoaded={input.onChange}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                </form>
              )}
            </Row>
          </div>
        )}
      />
    </>
  );
};

const typeOptions = [
  {
    label: 'Sport',
    value: 'sport',
  },
  {
    label: 'Health',
    value: 'health',
  },
  {
    label: 'Food',
    value: 'food',
  },
];
const validate = (values: Partial<NewsItem>) => {
  const errors: ValidationErrorsObject<NewsItem> = {};
  if (!values.pubDate) {
    errors.pubDate = 'Required field!';
  }

  return errors;
};

const singleValidation = (value: string | number): string => {
  let error = '';
  if (!value || (typeof value === 'string' && !value.trim())) {
    error = 'Required field!';
  }
  return error;
};

export default memo(NewsForm);
