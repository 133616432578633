import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import CountrySelect from '../CountrySelect';
import Comment from './Comment';
import AddComment from './AddComment';
import Loading from 'components/Loading';

import apiClient from 'utils/feathersClient';

import { ListPagination, ApiListData } from 'types/common';
import {
  CommentsItem,
  CategoryType,
  OnAddComment,
  OnDeleteComment,
  CategoryTypeId,
} from 'types/components/comments';
import { RootState } from 'store/types';

type CommentsListProps = {
  categoryType: CategoryType;
  categoryId?: number;
  title?: string;
};

const CommentsList: React.FC<CommentsListProps> = props => {
  const { categoryType, categoryId, title } = props;
  const { user } = useSelector<RootState, RootState['user']>(state => state.user);

  const isTypeRecord = categoryType === 'entity';

  const [countryId, setCountryId] = useState<number>(null);
  const [search, setSearch] = useState<string>('');
  const [list, setList] = useState<CommentsItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<ListPagination>({
    page: 1,
    limit: 10,
    total: 100,
    hasMore: true,
  });

  const fetchComments = async (page?: number) => {
    setIsLoading(true);
    let p = page || pagination.page;

    let service: string = '';
    let query: { [key: string]: any } = {};

    if (isTypeRecord) {
      service = 'content/record-comments';
      query = {
        $joins: true,
        $sort: { createdAt: -1 },
        replyId: null,
        $limit: pagination.limit,
        $skip: p - 1 > 0 ? (p - 1) * pagination.limit : 0,
      };
    } else {
      service = 'comments';
      query = {
        $joins: true,
        $sort: { createdAt: -1 },
        replyId: null,
        $limit: pagination.limit,
        $skip: p - 1 > 0 ? (p - 1) * pagination.limit : 0,
        [categoryType + 'Id']: categoryId.toString(),
      };
    }

    if (countryId) {
      query.countryId = countryId;
    }
    if (search) {
      query.$search = search;
    }

    const result: ApiListData<CommentsItem> = await apiClient.service(service).find({ query });

    const newArray = list.concat(result.data);
    if (page === 1) {
      setList(result.data);
    } else {
      setList(newArray);
    }

    const limit = pagination.limit;
    const newPage = p + 1;
    const total = result.total;
    const hasMore = (newPage - 1) * limit < total;

    setPagination({
      limit,
      page: newPage,
      total,
      hasMore,
    });
    setIsLoading(false);
  };

  const onAddComment: OnAddComment = (comment, replyId) => {
    if (replyId) {
      const newList = list.map(el => {
        if (el.id === replyId) {
          return { ...el, replies: [...el.replies, comment] };
        }
        return el;
      });
      setList(newList);
    } else {
      setList([comment, ...list]);
    }
  };

  const onDeleteComment: OnDeleteComment = (commentId, parentCommentId) => {
    if (parentCommentId) {
      const newList = list.map(el => {
        if (el.id === parentCommentId) {
          return {
            ...el,
            replies: el.replies.filter(subcomment => subcomment.id !== commentId),
          };
        }
        return el;
      });
      setList(newList);
    } else {
      const newList = list.filter(el => el.id !== commentId);
      setList(newList);
    }
  };

  const onPatchComment: OnAddComment = (comment, replyId) => {
    if (replyId) {
      const newList = list.map(el => {
        if (el.id === replyId) {
          return {
            ...el,
            replies: el.replies.map(reply => (reply.id === comment.id ? comment : reply)),
          };
        }
        return el;
      });
      setList(newList);
    } else {
      const newList = list.map(el => (el.id === comment.id ? comment : el));
      setList(newList);
    }
  };

  useEffect(() => {
    const channelName = isTypeRecord ? 'content/record-comments' : 'comments';
    apiClient.service(channelName).on('created', (comment: CommentsItem) => {
      const idOrNull: number = comment[(categoryType + 'Id') as CategoryTypeId];
      if (idOrNull) {
        // if we're here then it's actually id
        if (idOrNull === categoryId) {
          onAddComment(comment, comment.replyId);
        } else if (isTypeRecord) {
          onAddComment(comment, comment.replyId);
        }
      }
    });
    apiClient.service(channelName).on('patched', (comment: CommentsItem) => {
      const idOrNull: number = comment[(categoryType + 'Id') as CategoryTypeId];
      if (idOrNull) {
        // if we're here then it's actually id
        if (idOrNull === categoryId) {
          onPatchComment(comment, comment.replyId);
        } else if (isTypeRecord) {
          onPatchComment(comment, comment.replyId);
        }
      }
    });

    return () => {
      apiClient.service(channelName).removeListener('created');
      apiClient.service(channelName).removeListener('patched');
    };
  });

  const onSearch = (key: string) => {
    if (key === 'Enter') {
      fetchComments(1);
    }
  };

  useEffect(() => {
    fetchComments(1);
    // eslint-disable-next-line
  }, [countryId]);

  return (
    <div className="comments">
      {isLoading && <div className="loading-mask" />}

      <div className="search-field">
        <div>
          <input
            type="text"
            placeholder="Search for comments..."
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
            onKeyUp={(e: React.KeyboardEvent) => onSearch(e.key)}
          />
        </div>
        <div className="search-field--tag">
          <div />
          Comments
        </div>
      </div>
      <div className="comments__head">
        <h1 className="comments__title">{title || 'Comments'}</h1>
        <CountrySelect onSelect={country => setCountryId(country ? country.id : null)} />
      </div>

      <div className="comments__wrapper">
        {!isTypeRecord && user.role === 'editor' && (
          <>
            <AddComment categoryType={categoryType} categoryId={categoryId} countryId={countryId} />
            <hr className="comments__divider" />
          </>
        )}
        {list.map(comment => (
          <Comment
            key={comment.id}
            comment={comment}
            categoryId={categoryId}
            categoryType={categoryType}
            isTypeRecord={isTypeRecord}
            onDeleteComment={onDeleteComment}
          />
        ))}
        {pagination.hasMore && !isLoading && (
          <button className="button-showmore" onClick={() => fetchComments()}>
            Show more
          </button>
        )}
        {isLoading && <Loading style={{ transform: 'scale(0.8)', height: '68px' }} />}
      </div>
    </div>
  );
};

CommentsList.defaultProps = {
  title: 'Comments',
  categoryId: null,
};

export default CommentsList;
