import React from 'react';
import ReactSelect from 'react-select-async-paginate';

import apiClient from 'utils/feathersClient';

import { TagsItem, ApiListData } from 'types/common';

type TagsSelectProps = {
  value: TagsItem[];
  onChange: (value: TagsItem[]) => void;
  placeholder?: string;
  className?: string;
};

const TagsSelect: React.FC<TagsSelectProps> = props => {
  const fetchTags = async (search: string, prevOptions: TagsItem[], additional: any) => {
    const limit = 15;
    const page = prevOptions.length / limit;
    if (additional && page * limit >= additional.total) {
      return {
        options: [] as any[],
        hasMore: false,
      };
    }

    const query: any = {
      $limit: limit,
      $skip: page * limit,
    };
    if (search) {
      query.$search = search;
    }
    const result: ApiListData<TagsItem> = await apiClient.service('tags').find({ query });

    if (search && result.data.every((e: TagsItem) => e.title !== search)) {
      result.data.push({ title: search });
    }
    return {
      options: result.data,
      hasMore: page * limit < result.total,
      additional: {
        total: result.total,
      },
    };
  };

  return (
    // @ts-ignore
    <ReactSelect
      {...props}
      isMulti
      className="async-select"
      classNamePrefix="async-select"
      getOptionLabel={item => item.title}
      getOptionValue={item => item.title}
      closeMenuOnSelect={false}
      maxMenuHeight={230}
      loadOptions={(...args) => fetchTags(...args)}
    />
  );
};

TagsSelect.defaultProps = {
  className: '',
};

export default TagsSelect;
