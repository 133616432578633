/**
 *
 *
 * CustomSelect
 *
 */

import React from 'react';
import { Select } from '@buffetjs/core';
import { useTranslation } from 'react-i18next';

import { useWysiwyg } from '../contexts/Wysiwyg';
import { SELECT_OPTIONS } from './constants';
import SelectWrapper from './SelectWrapper';

const CustomSelect = () => {
  const { isPreviewMode, headerValue, isFullscreen, handleChangeSelect } = useWysiwyg();

  const [t] = useTranslation();
  const options = SELECT_OPTIONS.map(el => ({
    label: t(el.id),
    value: el.value,
  }));

  return (
    <SelectWrapper isFullscreen={isFullscreen}>
      <Select
        disabled={isPreviewMode}
        name={`headerSelect-${Math.random()}`}
        onChange={handleChangeSelect}
        value={headerValue}
        options={options}
      />
    </SelectWrapper>
  );
};

export default CustomSelect;
