import { SET_ACTIVE_LANGUAGE, GlobalState, GlobalActionType } from './types';

const initialState: GlobalState = {
  activeLanguage: 'en',
};

const userReducer = (state = initialState, { type, payload }: GlobalActionType) => {
  switch (type) {
    case SET_ACTIVE_LANGUAGE:
      return {
        ...state,
        activeLanguage: payload,
      };
    default:
      return state;
  }
};

export default userReducer;
