/*
 *
 * HomePage
 *
 */
import React, { useState, useEffect, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from '@buffetjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';

import PageTitle from 'components/PageTitle';
import Loading from 'components/Loading';

import apiClient from 'utils/feathersClient';

import { WorkoutsItem } from 'types/pages/workouts';
import { CustomRow, TableHeaders } from 'types/common';

const levelOptions: {
  [k: number]: string;
} = {
  0: 'Tutorial',
  1: 'Balance',
  2: 'Basic',
  3: 'Boost',
  4: 'Beast',
};

const HomePage: React.FC<{}> = () => {
  const history = useHistory();
  const [t] = useTranslation();

  const [workouts, setWorkouts] = useState<WorkoutsItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    if (isLoading) {
      return false;
    }

    const result: WorkoutsItem[] = await apiClient.service('content/workouts').find();

    setWorkouts(result);
    setIsLoading(false);
  };

  const customRow = ({ row }: CustomRow<WorkoutsItem>) => {
    const { id, level, title, description } = row;
    return (
      <tr onClick={() => history.push('/modes/' + id)}>
        <td>
          <p>{levelOptions[level] ? levelOptions[level] : level}</p>
        </td>
        <td>
          <p>{title}</p>
        </td>
        <td>
          {description && description.length > 55 ? (
            <p>{description.slice(0, 55) + '...'}</p>
          ) : (
            <p>{description}</p>
          )}
        </td>
        <td onClick={event => event.stopPropagation()}>
          <p>
            <FontAwesomeIcon
              className="ml15"
              style={{ transform: 'scale(0.8)' }}
              icon={faPencilAlt}
              onClick={() => history.push('/modes/' + id)}
            />
          </p>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageTitle title={t('HomePage.helmet.title')} />

      <div>
        <Row className="row" justify="space-between">
          <div>
            <h1>Modes</h1>
            <p style={{ fontSize: '1.3rem', color: '#787e8f' }}>
              {workouts.length} {workouts.length > 1 ? 'entries' : 'entry'} found
            </p>
          </div>
        </Row>

        <Row className="mt40">
          {isLoading ? (
            <Loading style={{ minHeight: '740px' }} />
          ) : (
            <Table headers={headers} customRow={customRow} rows={workouts} />
          )}
        </Row>
      </div>
    </>
  );
};

const headers: TableHeaders<WorkoutsItem> = [
  {
    name: 'level',
    value: 'level',
  },
  {
    name: 'Title',
    value: 'title',
  },
  {
    name: 'Description',
    value: 'description',
  },
  {
    name: '',
    value: '',
  },
];

export default memo(HomePage);
