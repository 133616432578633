/*
 *
 * HomePage
 *
 */
import React, { memo } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import PageTitle from 'components/PageTitle';
import Comments from 'components/Comments';

const ChallengeComments: React.FC<{}> = () => {
  const {
    params: { id: challengeId },
  } = useRouteMatch();
  const history = useHistory();
  const [t] = useTranslation();

  return (
    <>
      <PageTitle title={t('HomePage.helmet.title')} />

      <div className="back-button" onClick={() => history.goBack()}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>

      <div>
        <Comments
          categoryType="challenge"
          categoryId={+challengeId}
          title={`Challenge №${challengeId} - Posts`}
        />
      </div>
    </>
  );
};

export default memo(ChallengeComments);
