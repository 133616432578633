export const SET_ACTIVE_LANGUAGE = 'SET_ACTIVE_LANGUAGE';

export interface GlobalState {
  activeLanguage: string;
}

interface SetActiveLanguage {
  type: typeof SET_ACTIVE_LANGUAGE;
  payload: string;
}

export type GlobalActionType = SetActiveLanguage;
