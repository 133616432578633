/*
 *
 * HomePage
 *
 */
import React, { useState, memo } from 'react';
import Pagination from 'react-paginate';
import { Table, Select } from '@buffetjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';

import PageTitle from 'components/PageTitle';
import CustomPopconfirm from 'components/CustomPopconfirm';

import { toDateFormat } from 'utils';
import { NewsItem } from 'types/pages/news';
import { TablePagination, CustomRow, TableHeaders } from 'types/common';


const DocumentsPage: React.FC<{}> = () => {
  const [t] = useTranslation();

  const [search, setSearch] = useState<string>('');
  const [pagination] = useState<TablePagination>({ total: 6, limit: 10, page: 1 });


  const customRow = ({ row }: CustomRow<NewsItem>) => {
    const { id, title, description, type, pubDate, read } = row;

    return (
      <tr onClick={() => window.open("/richiesta-di-convocazione-di-assemblea-per-la-revoca-dellamministratore.pdf", "_blank")}>
        <td>
          <p>{id}</p>
        </td>
        <td>
          <p>{title}</p>
        </td>
        <td>
          <p>{description}</p>
        </td>
        <td>
          <p>{type}</p>
        </td>
        <td>
          <p>{toDateFormat(pubDate, 'HH:mm DD.MM.YYYY')}</p>
        </td>
        <td>
          <p>{read}</p>
        </td>
        <td onClick={event => event.stopPropagation()}>
          <p>
            {/* <FontAwesomeIcon
              style={{ transform: 'scale(0.8)' }}
              icon={faPencilAlt}
              onClick={() => history.push(`/news/${id}`)}
            /> */}
            <CustomPopconfirm
              title="Are you sure you want to delete this?"
              onConfirm={() => { }}
              okText="Yes"
              cancelText="No"
            >
              <FontAwesomeIcon
                className="ml15"
                style={{ transform: 'scale(0.8)' }}
                icon={faTrash}
              />
            </CustomPopconfirm>
          </p>
        </td>
      </tr>
    );
  };

  return (
    <>
      <PageTitle title={t('HomePage.helmet.title')} />

      <div>
        <Row justify="space-between" align="middle">
          <div>
            <h1>Documents</h1>
            <p style={{ fontSize: '1.3rem', color: '#787e8f' }}>
              {mockDocuments.length} {mockDocuments.length > 1 ? 'entries' : 'entry'} found
            </p>
          </div>
          {/* <Button
            color="primary"
            icon={<FontAwesomeIcon icon={faPlus} />}
            label="Add Documents"
            onClick={() => history.push(`/news/add`)}
          /> */}
        </Row>

        <div className="search-field">
          <div>
            <input
              type="text"
              placeholder="Search for an entry..."
              value={search}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
              onKeyUp={(e: React.KeyboardEvent) => { }}
            />
          </div>
          <div className="search-field--tag">
            <div />
            Documents
          </div>
        </div>

        <Row className="mt40">

          <Table headers={headers} rows={mockDocuments} customRow={customRow} />

        </Row>

        <Row className="mt40" justify="space-between">
          <Select
            value={pagination.limit}
            onChange={(e: any) => { }}
            options={paginationOptions}
            name="select"
            className="pagination-select"
          />
          <Pagination
            containerClassName="pagination__container"
            pageCount={Math.ceil(pagination.total / pagination.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            previousLabel={<span>&lt;</span>}
            nextLabel={<span>&gt;</span>}
            onPageChange={({ selected }) => { }}
          />
        </Row>
      </div>
    </>
  );
};

const headers: TableHeaders<NewsItem> = [
  {
    name: 'Id',
    value: 'id',
    isSortEnabled: true,
  },
  {
    name: 'Title',
    value: 'title',
  },
  {
    name: 'Description',
    value: 'description',
    isSortEnabled: true,
  },
  {
    name: 'Type',
    value: 'type',
    isSortEnabled: true,
  },
  {
    name: 'Pub date',
    value: 'pubDate',
    isSortEnabled: true,
  },
  {
    name: 'Read status',
    value: 'pubDate',
    isSortEnabled: true,
  },
  {
    name: '',
    value: '',
  },
];

const mockDocuments = [
  {
    id: 1,
    title: 'Convocazione di assemblea',
    description: 'la prossima assemblea sara convocata in formato video connza',
    type: 'Administrator',
    pubDate: '2021-04-01T10:50:58.424Z',
    read: "All 78/78"
  },
  {
    id: 2,
    title: 'Contratto di assicurazione dello stabile',
    description: 'IL contratto di assicurazione dello stabile con detagli',
    type: 'General',
    pubDate: '2021-05-08T14:40:58.424Z',
    read: "10/78"
  },
  {
    id: 3,
    title: 'Inventario dei beni comuni',
    description: 'La lista di tutti i beni comuni del condominio',
    type: 'General',
    pubDate: '2021-03-01T16:10:58.424Z',
    read: "72/78"
  },
  {
    id: 4,
    title: 'Compenso dell\'amministratore di condominio',
    description: 'Tutti gli detagli della compasazione del amministrazione del condominio',
    type: 'Administrator',
    pubDate: '2021-06-08T10:52:58.424Z',
    read: "70/78"
  },
]

const paginationOptions = [10, 20, 50];

export default memo(DocumentsPage);
