import BasicLayout from 'layouts/BasicLayout';
// import BlankLayout from 'layouts/BlankLayout';
// import SecurityLayout from 'layouts/SecurityLayout';
import UserLayout from 'layouts/UserLayout';

import NotFound from 'pages/404';
import Forbidden from 'pages/403';
import UserLogin from 'pages/auth/Login';
// CONTENT TYPES
import Administrators from 'pages/Administrators';
import AdministratorsForm from 'pages/Administrators/Form';
import Faqs from 'pages/Faqs';
import FaqsForm from 'pages/Faqs/Form';
import Troubleshootings from 'pages/Troubleshooting';
import TroubleshootingsForm from 'pages/Troubleshooting/Form';
import Usermanuals from 'pages/Usermanual';
import UsermanualsForm from 'pages/Usermanual/Form';
import News from 'pages/News';
import NewsForm from 'pages/News/Form';
import Pages from 'pages/Pages';
import PagesForm from 'pages/Pages/Form';
import Workouts from 'pages/Workouts';
import WorkoutsForm from 'pages/Workouts/Form';
import LeaguesForm from 'pages/Leagues/Form';
import Charities from 'pages/Charities';
import CharitiesForm from 'pages/Charities/Form';
import RecordComments from 'pages/RecordComments/Comments';
import Documents from 'pages/Documents';

// API
import Partners from 'pages/Partners';
import PartnersForm from 'pages/Partners/Form';
import Groups from 'pages/Groups';
import GroupsForm from 'pages/Groups/Form';
import GroupsPosts from 'pages/Groups/Comments';
import Challenges from 'pages/Challenges';
import ChallengesForm from 'pages/Challenges/Form';
import ChallengesPosts from 'pages/Challenges/Comments';
import Quizzes from 'pages/Quizzes';
import QuizzesForm from 'pages/Quizzes/Form';
import Settings from 'pages/Settings';
import SettingsContentForm from 'pages/Settings/ContentSettings';
import SettingsNotificationsList from 'pages/Settings/NotificationsSettings/List';
import SettingsNotificationsForm from 'pages/Settings/NotificationsSettings/Form';

export default [
  {
    name: '404',
    path: '/exception/404',
    component: NotFound,
    layout: BasicLayout,
    hideInMenu: true,
  },
  {
    name: '403',
    path: '/exception/403',
    component: Forbidden,
    layout: BasicLayout,
    hideInMenu: true,
  },
  {
    name: 'Login',
    path: '/user/login',
    component: UserLogin,
    layout: UserLayout,
    hideInMenu: true,
  },

  // ----- CONTENT TYPES -----
  {
    name: 'CONTENT TYPES',
    isDivider: true,
    path: '/content-type-divider',
    roles: ['admin', 'editor'],
  },
  // Administrators
  {
    name: 'Administrators',
    path: '/administrators',
    isProtected: true,
    component: Administrators,
    roles: ['admin'],
    layout: BasicLayout,
  },
  {
    name: 'AdministratorsAdd',
    path: '/administrators/add',
    isProtected: true,
    component: AdministratorsForm,
    layout: BasicLayout,
    roles: ['admin'],
    hideInMenu: true,
  },
  {
    name: 'AdministratorsEdit',
    path: '/administrators/:id',
    isProtected: true,
    component: AdministratorsForm,
    layout: BasicLayout,
    roles: ['admin'],
    hideInMenu: true,
  },
  // Faqs
  {
    name: 'Faqs',
    path: '/faqs',
    isProtected: true,
    component: Faqs,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  {
    name: 'FaqsAdd',
    path: '/faqs/add',
    isProtected: true,
    component: FaqsForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  {
    name: 'FaqsEdit',
    path: '/faqs/:id',
    isProtected: true,
    component: FaqsForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  // Troubleshooting
  {
    name: 'Troubleshootings',
    path: '/troubleshootings',
    isProtected: true,
    component: Troubleshootings,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  {
    name: 'TroubleshootingsAdd',
    path: '/troubleshootings/add',
    isProtected: true,
    component: TroubleshootingsForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  {
    name: 'TroubleshootingsEdit',
    path: '/troubleshootings/:id',
    isProtected: true,
    component: TroubleshootingsForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  // Usermanual
  {
    name: 'Usermanuals',
    path: '/user-manuals',
    isProtected: true,
    component: Usermanuals,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  {
    name: 'UsermanualsAdd',
    path: '/user-manuals/add',
    isProtected: true,
    component: UsermanualsForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  {
    name: 'UsermanualsEdit',
    path: '/user-manuals/:id',
    isProtected: true,
    component: UsermanualsForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  // News
  {
    name: 'Documents',
    path: '/Documents',
    isProtected: true,
    component: Documents,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
  },
  // News
  {
    name: 'News',
    path: '/news',
    isProtected: true,
    component: News,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
  },
  {
    name: 'NewsAdd',
    path: '/news/add',
    isProtected: true,
    component: NewsForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  {
    name: 'NewsEdit',
    path: '/news/:id',
    isProtected: true,
    component: NewsForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  // Pages
  {
    name: 'Pages',
    path: '/pages',
    isProtected: true,
    component: Pages,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
  },
  {
    name: 'PagesEdit',
    path: '/pages/:id',
    isProtected: true,
    component: PagesForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  // Workouts
  {
    name: 'Modes',
    path: '/modes',
    isProtected: true,
    component: Workouts,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
  },
  {
    name: 'ModesAdd',
    path: '/modes/add',
    isProtected: true,
    component: WorkoutsForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  {
    name: 'ModesEdit',
    path: '/modes/:id',
    isProtected: true,
    component: WorkoutsForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  // Charity Organizations
  {
    name: 'Charities',
    path: '/charities',
    isProtected: true,
    component: Charities,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
  },
  {
    name: 'CharitiesAdd',
    path: '/charities/add',
    isProtected: true,
    component: CharitiesForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  {
    name: 'CharitiesEdit',
    path: '/charities/:id',
    isProtected: true,
    component: CharitiesForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  // Leagues
  {
    name: 'Leagues',
    path: '/leagues',
    isProtected: true,
    component: LeaguesForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
  },
  // Record comments
  {
    name: 'Comments',
    path: '/comments',
    isProtected: true,
    component: RecordComments,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
  },
  // Help Center
  {
    isDropdown: true,
    name: 'Help Center',
    path: '/help-center',
    roles: ['admin', 'editor'],
    subItems: [
      // Faqs
      {
        name: 'Faqs',
        path: '/faqs',
      },
      // Troubleshooting
      {
        name: 'Troubleshootings',
        path: '/troubleshootings',
      },
      // Usermanual
      {
        name: 'User manuals',
        path: '/user-manuals',
      },
    ],
  },

  // ----- API -----
  {
    name: 'API',
    isDivider: true,
    path: '/api-divider',
    roles: ['admin', 'editor', 'partner'],
  },
  // Partners
  {
    name: 'Partners',
    path: '/partners',
    isProtected: true,
    component: Partners,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
  },
  {
    name: 'PartnersAdd',
    path: '/partners/add',
    isProtected: true,
    component: PartnersForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  {
    name: 'PartnersEdit',
    path: '/partners/:id',
    isProtected: true,
    component: PartnersForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  // Groups
  {
    name: 'Groups',
    path: '/groups',
    isProtected: true,
    component: Groups,
    layout: BasicLayout,
    roles: ['admin', 'editor', 'partner'],
  },
  {
    name: 'GroupsAdd',
    path: '/groups/add',
    isProtected: true,
    component: GroupsForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  {
    name: 'GroupsEdit',
    path: '/groups/:id',
    isProtected: true,
    component: GroupsForm,
    layout: BasicLayout,
    roles: ['admin', 'editor', 'partner'],
    hideInMenu: true,
  },
  {
    name: 'GroupsPosts',
    path: '/groups/:id/posts',
    isProtected: true,
    component: GroupsPosts,
    layout: BasicLayout,
    roles: ['admin', 'editor', 'partner'],
    hideInMenu: true,
  },
  // Challenges
  {
    name: 'Challenges',
    path: '/challenges',
    isProtected: true,
    component: Challenges,
    layout: BasicLayout,
    roles: ['admin', 'editor', 'partner'],
  },
  {
    name: 'ChallengesAdd',
    path: '/challenges/add',
    isProtected: true,
    component: ChallengesForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  {
    name: 'ChallengesPosts',
    path: '/challenges/:id/posts',
    isProtected: true,
    component: ChallengesPosts,
    layout: BasicLayout,
    roles: ['admin', 'editor', 'partner'],
    hideInMenu: true,
  },
  {
    name: 'ChallengesEdit',
    path: '/challenges/:id',
    isProtected: true,
    component: ChallengesForm,
    layout: BasicLayout,
    roles: ['admin', 'editor', 'partner'],
    hideInMenu: true,
  },
  // Quizzes
  {
    name: 'Quizzes',
    path: '/quizzes',
    isProtected: true,
    component: Quizzes,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
  },
  {
    name: 'QuizzesAdd',
    path: '/quizzes/add',
    isProtected: true,
    component: QuizzesForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  {
    name: 'QuizzesEdit',
    path: '/quizzes/:id',
    isProtected: true,
    component: QuizzesForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  // Settings
  {
    name: 'Settings',
    path: '/settings',
    isProtected: true,
    component: Settings,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
  },
  {
    name: 'SettingsContentEdit',
    path: '/settings/content',
    isProtected: true,
    component: SettingsContentForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  {
    name: 'SettingsNotificationsList',
    path: '/settings/notifications',
    isProtected: true,
    component: SettingsNotificationsList,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  {
    name: 'SettingsNotificationsAdd',
    path: '/settings/notifications/add',
    isProtected: true,
    component: SettingsNotificationsForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
  {
    name: 'SettingsNotificationsEdit',
    path: '/settings/notifications/:id',
    isProtected: true,
    component: SettingsNotificationsForm,
    layout: BasicLayout,
    roles: ['admin', 'editor'],
    hideInMenu: true,
  },
];
