/*
 *
 * HomePage
 *
 */
import React, { useState, useEffect, memo } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { Button, InputText, Toggle } from '@buffetjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { message, Modal, Row, Col } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import PageTitle from 'components/PageTitle';
import Loading from 'components/Loading';
import CategorySelect from 'components/CategorySelect';

import apiClient from 'utils/feathersClient';
import { isEmail } from 'utils';

import { PartnersItem } from 'types/pages/partners';
import { ValidationErrorsObject, TransformData } from 'types/common';

const PartnersForm: React.FC<{}> = () => {
  const history = useHistory();
  const {
    params: { id: partnerId },
  } = useRouteMatch();
  const [t] = useTranslation();

  const [partner, setPartner] = useState<PartnersItem | {}>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isEdit = partnerId ? true : false;

  const fetchData = async () => {
    setIsLoading(true);
    const result: PartnersItem = await apiClient.service('partners').get(partnerId);

    setPartner(result);
    setIsLoading(false);
  };

  const transformData: TransformData<PartnersItem> = values => {
    let challengesIds: number[] = [];
    let groupsIds: number[] = [];

    if (values.challenges && values.challenges.length) {
      challengesIds = values.challenges.map(el => el.id);
    }
    if (values.groups && values.groups.length) {
      groupsIds = values.groups.map(el => el.id);
    }

    return {
      ...values,
      type: 'partner',
      username: values.username.trim(),
      challengesIds,
      groupsIds,
      challenges: undefined,
      groups: undefined,
    };
  };

  const onSave = async (values: Partial<PartnersItem>) => {
    try {
      await apiClient.service('partners').patch(partnerId, transformData(values));
      message.success('Updated successfully!');
      history.push('/partners');
    } catch (e) {
      message.error(`Error while updating partner! ${e}`);
    }
  };

  const onAdd = async (values: Partial<PartnersItem>) => {
    try {
      await apiClient.service('partners').create(transformData(values));
      message.success('Added successfully!');
      history.push('/partners');
    } catch (e) {
      message.error(`Error while adding partner! ${e}`);
    }
  };

  const onDelete = async () => {
    try {
      await apiClient.service('partners').remove(partnerId);
      message.info('Deleted successfully!');
      history.push('/partners');
    } catch (e) {
      message.error(`Error while deleting partner! ${e}`);
    }
  };

  const openDeletePopup = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: onDelete,
    });
  };

  useEffect(() => {
    if (isEdit) {
      fetchData();
    } else {
      setPartner({
        isActive: true,
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageTitle title={t('HomePage.helmet.title')} />

      <div className="back-button" onClick={() => history.push('/partners')}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>

      <Form
        onSubmit={isEdit ? onSave : onAdd}
        validate={values => validate(values, isEdit)}
        initialValues={partner}
        render={({ handleSubmit, pristine, submitting, form: { reset } }) => (
          <div>
            <Row justify="space-between">
              {isEdit ? (
                <h1 className="align-center">Partner №{partnerId}</h1>
              ) : (
                <h1>Add Partner</h1>
              )}
              <div>
                {isEdit && (
                  <Button
                    color="delete"
                    label="Delete"
                    className="mr15"
                    icon={<FontAwesomeIcon icon={faTrashAlt} />}
                    onClick={openDeletePopup}
                  />
                )}
                <Button className="mr15" color="cancel" label="Reset" onClick={reset} />
                {isEdit ? (
                  <Button
                    color="primary"
                    label="Save"
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                  />
                ) : (
                  <Button
                    color="primary"
                    label="Add"
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                  />
                )}
              </div>
            </Row>

            <Row className="mt40">
              {isEdit && isLoading ? (
                <Loading style={{ minHeight: '400px' }} />
              ) : (
                <form className="form-container">
                  <Row>
                    <Col span={12}>
                      <Field name="username">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="username">Username</label>
                            <InputText
                              {...input}
                              className={
                                (meta.error || meta.submitError) && meta.touched
                                  ? 'invalid-field'
                                  : ''
                              }
                              id="username"
                              name="input"
                              type="text"
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>

                      <Field name="displayName">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="displayName">Display name</label>
                            <InputText
                              {...input}
                              className={
                                (meta.error || meta.submitError) && meta.touched
                                  ? 'invalid-field'
                                  : ''
                              }
                              id="displayName"
                              name="input"
                              type="text"
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>

                      <Field name="groups">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="groups">Groups</label>
                            <CategorySelect {...input} type="groups" partnerId={partnerId} />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="challenges">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="challenges">Challenges</label>
                            <CategorySelect {...input} type="challenges" partnerId={partnerId} />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>

                    <Col span={12}>
                      <Field name="email">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="email">Email</label>
                            <InputText
                              {...input}
                              id="email"
                              name="input"
                              type="email"
                              className={
                                (meta.error || meta.submitError) && meta.touched
                                  ? 'invalid-field'
                                  : ''
                              }
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="password">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="password">Password</label>
                            <InputText
                              {...input}
                              id="password"
                              name="input"
                              type="password"
                              className={
                                (meta.error || meta.submitError) && meta.touched
                                  ? 'invalid-field'
                                  : ''
                              }
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                input.onChange(e.target.value.replace(/\s/g, ''))
                              }
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="isActive">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="isActive">IsActive</label>
                            <Toggle
                              {...input}
                              value={input.value || false}
                              id="isPrivate"
                              name="toggle"
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>
                  </Row>
                </form>
              )}
            </Row>
          </div>
        )}
      />
    </>
  );
};

const validate = (values: Partial<PartnersItem>, isEdit: boolean) => {
  const errors: ValidationErrorsObject<PartnersItem> = {};
  if (!values.username || !values.username.trim()) {
    errors.username = 'Required field!';
  }
  if (!values.email) {
    errors.email = 'Required field!';
  }
  if (!values.displayName) {
    errors.displayName = 'Required field!';
  }
  if (!isEmail(values.email)) {
    errors.email = 'Enter valid email!';
  }
  if (isEdit && values.password && values.password.length <= 6) {
    errors.password =
      "Enter more than 6 characters or leave it blank(if you don't want to change it)!";
  }
  if (!isEdit && (!values.password || values.password.length <= 6)) {
    errors.password = 'Enter password with more than 6 characters!';
  }
  return errors;
};

export default memo(PartnersForm);
