import React, { useState, useEffect, useRef, SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { message } from 'antd';

import Loading from '../Loading';

import { RootState } from 'store/types';

import './index.css';

type FileUploaderProps = {
  disabled?: boolean;
  accept?: string;
  text?: string;
  fontSize?: string;
  className?: string;
  logoId?: number | null;
  onFileLoaded?: (id: number | null) => void;
};

const FileUploader: React.FC<FileUploaderProps> = props => {
  const { fontSize, disabled, text, accept, className, logoId, onFileLoaded } = props;

  const { token } = useSelector<RootState, RootState['user']>(state => state.user);

  const fileUploaderRef = useRef<HTMLInputElement>(null);

  const [imageUri, setImageUri] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, [logoId]);

  const init = async () => {
    if (!logoId) {
      removeImage();
      return false;
    }

    const image = await getImage(logoId);
    setImageUri(image.path || `${process.env.REACT_APP_API_URL}/uploads/${image.filename}`);
  };

  const getImage = async (id: number) => {
    setIsLoading(true);
    try {
      const upload = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/uploads/${id}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      return upload.data;
    } catch (err) {
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const onFileChange = (e: any) => {
    const files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    createImage(files[0]);
  };

  const createImage = async (file: any) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const { data } = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/uploads`,
        data: formData,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (onFileLoaded) {
        onFileLoaded(data[0].id);
      }
    } catch (e) {
      message.error(`Error while adding image, ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  const removeImage = () => {
    if (onFileLoaded) {
      onFileLoaded(null);
    }
    setImageUri('');
  };

  const openFileSelector = () => {
    if (!fileUploaderRef.current || disabled) return false;

    if (fileUploaderRef && fileUploaderRef.current) {
      fileUploaderRef.current.click();
    }
  };

  const preventDefaults = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div
      className={`uploader-wrapper ${className}`}
      onDrop={event => {
        preventDefaults(event);
        onFileChange(event);
      }}
      onDragEnter={preventDefaults}
      onDragOver={preventDefaults}
      onDragLeave={preventDefaults}
      style={{ fontSize }}
    >
      <div className={`file-selector ${imageUri ? 'display-none' : ''}`}>
        <div
          className={`add-new-logo align ${disabled ? 'cursor-default' : ''}`}
          onClick={openFileSelector}
        >
          {isLoading ? (
            <Loading style={{ position: 'absolute', zIndex: 6, transform: 'scale(0.7)' }} />
          ) : (
            <span>{!disabled ? text : ''}</span>
          )}
        </div>
        <input
          type="file"
          accept={accept}
          ref={fileUploaderRef}
          style={{ display: 'none' }}
          onChange={onFileChange}
        />
      </div>
      {imageUri ? (
        <div className="image-container" onClick={openFileSelector}>
          <div
            style={{ backgroundImage: `url(${imageUri}` }}
            className={`image ${!disabled ? 'blur cursor-pointer' : ''} ${
              isLoading ? 'force-blur' : ''
            }`}
          />
          {isLoading && (
            <Loading style={{ position: 'absolute', zIndex: 6, transform: 'scale(0.7)' }} />
          )}
          {!disabled && (
            <>
              <p className="update-photo">Change image</p>
              <div
                className="remove-icon"
                onClick={event => {
                  event.stopPropagation();
                  removeImage();
                }}
              />
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

FileUploader.defaultProps = {
  disabled: false,
  text: 'Upload image',
  accept: '',
  fontSize: '24px',
  logoId: null,
  onFileLoaded: () => {},
};

export default FileUploader;
