import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
// import styles from './index.module.less';

type CustomPopconfirmProps = {
  title: string;
  okText: string;
  cancelText: string;
  onConfirm: () => void;
};

const CustomPopconfirm: React.FC<CustomPopconfirmProps> = props => {
  const { children, onConfirm, title, okText, cancelText } = props;

  const onClick = () => {
    Modal.confirm({
      title,
      icon: <ExclamationCircleOutlined />,
      okText,
      cancelText,
      onOk: onConfirm,
    });
  };

  return <span onClick={onClick}>{children}</span>;
};

export default CustomPopconfirm;
