import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Button, InputText, Checkbox } from '@buffetjs/core';

import PageTitle from '../../../components/PageTitle';

import StrapiImage from '../../../assets/images/logo_strapi.png';

import { userLogin } from 'store/user/actions';
import { RootState } from 'store/types';

import styles from './style.module.less';

interface LoginProps extends RouteComponentProps {}

const Login: React.FC<LoginProps> = props => {
  const { history } = props;

  const { isAuth } = useSelector<RootState, RootState['user']>(state => state.user);

  const dispatch = useDispatch();
  const onUserLogin = (payload: any) => dispatch(userLogin(payload));

  const [t] = useTranslation();
  const [autoLogin, setAutoLogin] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const onLogin = async (values: any) => {
    if (errMessage) return;

    try {
      await onUserLogin({ ...values });
    } catch (e) {
      if (e.code === 401) {
        setErrMessage('Identifier or password invalid.');
      } else {
        setErrMessage(e.message);
      }
    }
  };

  const removeErrorMessage = () => {
    if (errMessage) {
      setErrMessage('');
    }
  };

  useEffect(() => {
    if (isAuth) {
      history.push('/');
    }
  }, [isAuth, history]);

  return (
    <div className={styles.main}>
      <PageTitle title="Login" />
      <Form
        onSubmit={onLogin}
        render={({ handleSubmit, submitting }) => (
          <div>
            <div className={styles.logo}>
              <img src={StrapiImage} alt="strapi" />
            </div>
            <div className={styles['form-block']}>
              <div className={styles.errorText}>{errMessage}</div>

              <Field name="username" validate={singleValidation}>
                {({ input, meta }) => (
                  <div className="form-item">
                    <label htmlFor="username">{t('Auth.form.login.username.label')}</label>
                    <InputText
                      {...input}
                      className={
                        errMessage || ((meta.error || meta.submitError) && meta.touched)
                          ? 'invalid-field'
                          : ''
                      }
                      id="username"
                      placeholder={t('Auth.form.login.username.placeholder')}
                      name="input"
                      type="text"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        input.onChange(e.target.value);
                        removeErrorMessage();
                      }}
                    />
                    {(meta.error || meta.submitError) && meta.touched && (
                      <span className="error-message">{meta.error}</span>
                    )}
                  </div>
                )}
              </Field>

              <Field name="password" validate={singleValidation}>
                {({ input, meta }) => (
                  <div className="form-item">
                    <label htmlFor="password">{t('Auth.form.login.password.label')}</label>
                    <InputText
                      {...input}
                      id="password"
                      name="input"
                      type="password"
                      className={
                        errMessage || ((meta.error || meta.submitError) && meta.touched)
                          ? 'invalid-field'
                          : ''
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        input.onChange(e.target.value);
                        removeErrorMessage();
                      }}
                      onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === 'Enter') {
                          handleSubmit();
                        }
                      }}
                    />
                    {(meta.error || meta.submitError) && meta.touched && (
                      <span className="error-message">{meta.error}</span>
                    )}
                  </div>
                )}
              </Field>
              <div className={styles['submit-block']}>
                <Checkbox
                  message={t('Auth.form.login.rememberMe.label')}
                  name="remember"
                  value={autoLogin}
                  onChange={(e: any) => setAutoLogin(e.target.value)}
                />
                <Button
                  className={styles['login']}
                  color="primary"
                  label={t('Auth.form.button.login')}
                  disabled={submitting}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};

const singleValidation = (value: string | number): string => {
  let error = '';
  if (!value || (typeof value === 'string' && !value.trim())) {
    error = 'This value is required.';
  }
  return error;
};

export default withRouter(Login);
