import React, { useState, useEffect, memo } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { Button, InputText, Toggle, Select } from '@buffetjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { message, Modal, Row, Col } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import PageTitle from 'components/PageTitle';
import Loading from 'components/Loading';

import { isEmail } from 'utils';
import apiClient from 'utils/feathersClient';

import { ValidationErrorsObject, TransformData } from 'types/common';
import { AdministratorsItem } from 'types/pages/administrators';

const AdministratorsForm: React.FC<{}> = () => {
  const history = useHistory();
  const {
    params: { id: administratorId },
  } = useRouteMatch();
  const [t] = useTranslation();

  const [administrator, setAdministrator] = useState<Partial<AdministratorsItem>>({
    type: 'admin',
    isActive: true,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isEdit = administratorId ? true : false;

  const fetchData = async () => {
    setIsLoading(true);
    const result: AdministratorsItem = await apiClient.service('partners').get(administratorId);

    setAdministrator(result);
    setIsLoading(false);
  };

  const transformData: TransformData<AdministratorsItem> = values => {
    return {
      ...values,
      displayName: values.displayName.trim(),
      username: values.username.trim(),
    };
  };

  const onSave = async (values: Partial<AdministratorsItem>) => {
    try {
      await apiClient.service('partners').patch(administratorId, transformData(values));
      message.success('Updated successfully!');
      history.push('/administrators');
    } catch (e) {
      message.error(`Error while updating admin! ${e}`);
    }
  };

  const onAdd = async (values: Partial<AdministratorsItem>) => {
    try {
      await apiClient.service('partners').create(transformData(values));
      message.success('Added successfully!');
      history.push('/administrators');
    } catch (e) {
      message.error(`Error while adding admin! ${e}`);
    }
  };

  const onDelete = async () => {
    try {
      await apiClient.service('partners').remove(administratorId);
      message.info('Deleted successfully!');
      history.push('/administrators');
    } catch (e) {
      message.error(`Error while deleting admin! ${e}`);
    }
  };

  const openDeletePopup = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: onDelete,
    });
  };

  useEffect(() => {
    if (isEdit) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [administratorId]);

  return (
    <>
      <PageTitle title={t('HomePage.helmet.title')} />

      <div className="back-button" onClick={() => history.push('/administrators')}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>

      <Form
        onSubmit={isEdit ? onSave : onAdd}
        validate={values => validate(values, isEdit)}
        initialValues={administrator}
        render={({ handleSubmit, pristine, submitting, form: { reset } }) => (
          <div>
            <Row justify="space-between">
              {isEdit ? (
                <h1 className="align-center">Administrator №{administratorId}</h1>
              ) : (
                <h1>Add Administrator</h1>
              )}
              <div>
                {isEdit && (
                  <Button
                    color="delete"
                    label="Delete"
                    className="mr15"
                    icon={<FontAwesomeIcon icon={faTrashAlt} />}
                    onClick={openDeletePopup}
                  />
                )}
                <Button className="mr15" color="cancel" label="Reset" onClick={reset} />
                {isEdit ? (
                  <Button
                    color="primary"
                    label="Save"
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                  />
                ) : (
                  <Button
                    color="primary"
                    label="Add"
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                  />
                )}
              </div>
            </Row>

            <Row className="mt40">
              {isEdit && isLoading ? (
                <Loading style={{ minHeight: '400px' }} />
              ) : (
                <form className="form-container">
                  <Row>
                    <Col span={12}>
                      <Field name="username">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="username">Username</label>
                            <InputText
                              {...input}
                              className={
                                (meta.error || meta.submitError) && meta.touched
                                  ? 'invalid-field'
                                  : ''
                              }
                              id="username"
                              name="input"
                              type="text"
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>

                      <Field name="displayName">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="displayName">Display Name</label>
                            <InputText
                              {...input}
                              className={
                                (meta.error || meta.submitError) && meta.touched
                                  ? 'invalid-field'
                                  : ''
                              }
                              id="displayName"
                              name="input"
                              type="text"
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="type">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="type">Role</label>
                            <Select {...input} id="type" name="select" options={rolesOptions} />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>

                    <Col span={12}>
                      <Field name="email">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="email">Email</label>
                            <InputText
                              {...input}
                              id="email"
                              name="input"
                              type="email"
                              className={
                                (meta.error || meta.submitError) && meta.touched
                                  ? 'invalid-field'
                                  : ''
                              }
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="password">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="password">Password</label>
                            <InputText
                              {...input}
                              id="password"
                              name="input"
                              type="password"
                              className={
                                (meta.error || meta.submitError) && meta.touched
                                  ? 'invalid-field'
                                  : ''
                              }
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                input.onChange(e.target.value.replace(/\s/g, ''))
                              }
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="isActive">
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="isActive">Is Active</label>
                            <Toggle
                              {...input}
                              value={input.value || false}
                              id="isActive"
                              name="toggle"
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>
                  </Row>
                </form>
              )}
            </Row>
          </div>
        )}
      />
    </>
  );
};

const rolesOptions = [
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Editor',
    value: 'editor',
  },
];

const validate = (values: Partial<AdministratorsItem>, isEdit: boolean) => {
  const errors: ValidationErrorsObject<AdministratorsItem> = {};
  if (!values.username || !values.username.trim()) {
    errors.username = 'Required field!';
  }
  if (!isEmail(values.email)) {
    errors.email = 'Enter valid email!';
  }
  if (!values.email) {
    errors.email = 'Required field!';
  }
  if (!values.displayName || !values.displayName.trim()) {
    errors.displayName = 'Required field!';
  }
  if (isEdit && values.password && values.password.length <= 6) {
    errors.password =
      "Enter more than 6 characters or leave it blank(if you don't want to change it)!";
  }
  if (!isEdit && (!values.password || values.password.length <= 6)) {
    errors.password = 'Enter password with more than 6 characters!';
  }
  return errors;
};

export default memo(AdministratorsForm);
