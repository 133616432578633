import { Button, Result } from 'antd';
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface NotFoundPageProps extends RouteComponentProps {}

const NoFoundPage: React.FC<NotFoundPageProps> = props => {
  const { history } = props;

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={() => history.push('/')}>
          Back Home
        </Button>
      }
    />
  );
};

export default withRouter(NoFoundPage);
