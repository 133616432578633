/*
 *
 * HomePage
 *
 */
import React, { useState, useEffect, memo } from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from 'react-paginate';
import { Table, Select, Button } from '@buffetjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { message, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import PageTitle from 'components/PageTitle';
import Loading from 'components/Loading';
import CustomPopconfirm from 'components/CustomPopconfirm';

import apiClient from 'utils/feathersClient';

import { FaqsItem } from 'types/pages/faqs';
import { TablePagination, ApiListData, CustomRow, TableHeaders } from 'types/common';

const HomePage: React.FC<{}> = () => {
  const history = useHistory();
  const [t] = useTranslation();

  const [faqs, setFaqs] = useState<FaqsItem[]>([]);
  const [search, setSearch] = useState<string>('');
  const [pagination, setPagination] = useState<TablePagination>({ total: 100, limit: 10, page: 1 });
  // const [filters, setFilters] = useState({});
  // const [activeFilters, setActiveFilters] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async (paginationObj: Partial<TablePagination> = {}) => {
    setIsLoading(true);
    if (isLoading) {
      return false;
    }
    const page = paginationObj.page || pagination.page;
    const limit = paginationObj.limit || pagination.limit;
    const query: any = {
      $limit: pagination.limit,
      $skip: pagination.page - 1 > 0 ? (pagination.page - 1) * pagination.limit : 0,
      $sort: { id: -1 },
      type: 'faq',
    };
    if (search) {
      query.$search = search;
    }
    const result: ApiListData<FaqsItem> = await apiClient.service('content/faqs').find({ query });

    setFaqs(result.data);
    setPagination({
      limit,
      page,
      total: result.total,
    });
    setIsLoading(false);
  };

  const onDelete = async (id: number) => {
    try {
      await apiClient.service('content/faqs').remove(id);
      message.info('Deleted successfully');
      fetchData();
    } catch (e) {
      message.error(`Error while deleting faq! ${e}`);
    }
  };

  const onSearch = (pressedButton: string) => {
    if (pressedButton === 'Enter') {
      fetchData({ page: 1 });
    }
  };

  const onLimitChange = (limit: number) => {
    fetchData({ page: 1, limit });
  };

  const onPageChange = (selected: number) => {
    fetchData({ page: selected + 1 });
  };

  const customRow = ({ row }: CustomRow<FaqsItem>) => {
    const { id, title, description } = row;

    return (
      <tr onClick={() => history.push(`/faqs/${id}`)}>
        <td>
          <p>{id}</p>
        </td>
        <td>
          <p>{title}</p>
        </td>
        <td>
          <p>{description}</p>
        </td>
        <td onClick={event => event.stopPropagation()}>
          <p>
            <FontAwesomeIcon
              style={{ transform: 'scale(0.8)' }}
              icon={faPencilAlt}
              onClick={() => history.push(`/faqs/${id}`)}
            />
            <CustomPopconfirm
              title="Are you sure you want to delete this?"
              onConfirm={() => onDelete(id)}
              okText="Yes"
              cancelText="No"
            >
              <FontAwesomeIcon
                className="ml15"
                style={{ transform: 'scale(0.8)' }}
                icon={faTrash}
              />
            </CustomPopconfirm>
          </p>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageTitle title={t('HomePage.helmet.title')} />

      <div>
        <Row justify="space-between" align="middle">
          <div>
            <h1>Faqs</h1>
            <p style={{ fontSize: '1.3rem', color: '#787e8f' }}>
              {faqs.length} {faqs.length > 1 ? 'entries' : 'entry'} found
            </p>
          </div>
          <Button
            color="primary"
            icon={<FontAwesomeIcon icon={faPlus} />}
            label="Add Faq"
            onClick={() => history.push(`/faqs/add`)}
          />
        </Row>

        <div className="search-field">
          <div>
            <input
              type="text"
              placeholder="Search for an entry..."
              value={search}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
              onKeyUp={(e: React.KeyboardEvent) => onSearch(e.key)}
            />
          </div>
          <div className="search-field--tag">
            <div />
            Faq
          </div>
        </div>

        <Row className="mt40">
          {isLoading ? (
            <Loading style={{ minHeight: '740px' }} />
          ) : (
            <Table headers={headers} rows={faqs} customRow={customRow} />
          )}
        </Row>

        <Row className="mt40" justify="space-between">
          <Select
            style={{ width: 'auto' }}
            value={pagination.limit}
            onChange={(e: any) => onLimitChange(e.target.value)}
            options={paginationOptions}
            name="select"
            className="pagination-select"
          />
          <Pagination
            containerClassName="pagination__container"
            pageCount={Math.ceil(pagination.total / pagination.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            previousLabel={<span>&lt;</span>}
            nextLabel={<span>&gt;</span>}
            onPageChange={({ selected }) => onPageChange(selected)}
          />
        </Row>
      </div>
    </>
  );
};

const headers: TableHeaders<FaqsItem> = [
  {
    name: 'Id',
    value: 'id',
    isSortEnabled: true,
  },
  {
    name: 'Title',
    value: 'title',
  },
  {
    name: 'Description',
    value: 'description',
  },
  {
    name: '',
    value: '',
  },
];

const paginationOptions = [10, 20, 50];

export default memo(HomePage);
