import React from 'react';

import Avatar from './AvatarDropdown';
import Language from './LanguageDropdown';

import styles from './index.module.less';

const GlobalHeaderRight: React.FC<{}> = () => {
  const className = styles.right;

  return (
    <div className={className}>
      <Avatar />
      <Language />
    </div>
  );
};

export default GlobalHeaderRight;
