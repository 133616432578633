/*
 *
 * HomePage
 *
 */
import React, { useState, useEffect, memo } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { Button, InputText } from '@buffetjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { message, Modal, Row, Col } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import PageTitle from 'components/PageTitle';
import Wysiwyg from 'components/_strapi/Wysiwyg';
import Loading from 'components/Loading';
import LanguagesSelect from 'components/LanguageSelect';

import apiClient from 'utils/feathersClient';

import { PagesItem } from 'types/pages/pages';

const PagesForm: React.FC<{}> = () => {
  const history = useHistory();
  const {
    params: { id: pageId },
  } = useRouteMatch();
  const [t] = useTranslation();

  const [page, setPage] = useState<PagesItem | {}>({});
  // const [initialPageValues, setInitialPageValues] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>('en');

  const fetchData = async () => {
    setIsLoading(true);
    const result = await apiClient
      .service('content/pages')
      .get(pageId, { query: { $getAllLang: true } });

    setPage(result);
    setIsLoading(false);
  };

  const onSave = async (values: Partial<PagesItem>) => {
    try {
      await apiClient.service('content/pages').patch(pageId, values);
      message.success('Updated successfully!');
      history.push('/pages');
    } catch (e) {
      message.error(`Error while updating page! ${e}`);
    }
  };

  const onDelete = async () => {
    try {
      await apiClient.service('content/pages').create(pageId);
      message.info('Deleted successfully!');
      history.push('/pages');
    } catch (e) {
      message.error(`Error while deleting page! ${e}`);
    }
  };

  const openDeletePopup = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: onDelete,
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageTitle title={t('HomePage.helmet.title')} />

      <div className="back-button" onClick={() => history.push('/pages')}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>

      <Form
        onSubmit={onSave}
        initialValues={page}
        render={({ handleSubmit, pristine, submitting, form: { reset } }) => (
          <div>
            <Row justify="space-between">
              {<h1>Page №{pageId}</h1>}
              <div className="align-center">
                <LanguagesSelect
                  className="mr25"
                  initialValue={language}
                  onSelect={value => setLanguage(value.name)}
                />
                <Button
                  color="delete"
                  label="Delete"
                  className="mr15"
                  icon={<FontAwesomeIcon icon={faTrashAlt} />}
                  onClick={openDeletePopup}
                />
                <Button className="mr15" color="cancel" label="Reset" onClick={reset} />
                <Button
                  color="primary"
                  label="Save"
                  disabled={pristine || submitting}
                  onClick={handleSubmit}
                />
              </div>
            </Row>

            <Row className="mt40">
              {isLoading ? (
                <Loading style={{ minHeight: '280px' }} />
              ) : (
                <form className="form-container">
                  <Row className="row">
                    <Col span={10}>
                      <Field name={`${language}.title`} validate={singleValidation}>
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label htmlFor="title">Title</label>
                            <InputText
                              {...input}
                              id="title"
                              className={
                                (meta.error || meta.submitError) && meta.touched
                                  ? 'invalid-field'
                                  : ''
                              }
                              name="input"
                              type="text"
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>
                  </Row>

                  <Row className="mt25">
                    <Col span={24}>
                      <Field name={`${language}.content`}>
                        {({ input, meta }) => (
                          <div className="form-item">
                            <label>Content</label>
                            <Wysiwyg
                              {...input}
                              className={
                                (meta.error || meta.submitError) && meta.touched
                                  ? 'invalid-field'
                                  : ''
                              }
                              name="pages-wywiwyg"
                              lang={language}
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <span className="error-message">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>
                  </Row>
                </form>
              )}
            </Row>
          </div>
        )}
      />
    </>
  );
};

const singleValidation = (value: string | number): string => {
  let error = '';
  if (!value || (typeof value === 'string' && !value.trim())) {
    error = 'Required field!';
  }
  return error;
};

export default memo(PagesForm);
