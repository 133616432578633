import {
  SET_USER_TOKEN,
  SET_IS_USER_AUTH,
  SET_USER,
  UserState,
  UserActionType,
  USER_LOGOUT,
  USER_LOGIN,
  SET_PARTNER,
} from './types';
import { getToken, getUserState } from '../../utils/userStorage';

const token = getToken();
let user = getUserState();

const initialState: UserState = {
  token: token || null,
  isAuth: false,
  user: user || {},
  partner: {},

  status: token ? 'ok' : undefined,
};

const userReducer = (state = initialState, { type, payload }: UserActionType) => {
  switch (type) {
    case SET_USER_TOKEN:
      return {
        ...state,
        token: payload,
      };
    case SET_IS_USER_AUTH:
      return {
        ...state,
        isAuth: payload,
      };
    case SET_USER:
      return {
        ...state,
        user: payload,
      };
    case SET_PARTNER:
      return {
        ...state,
        partner: payload,
      };
    case USER_LOGIN:
      if (payload && typeof payload === 'object') {
        return {
          ...state,
          ...payload,
        };
      }
      return {
        ...state,
      };
    case USER_LOGOUT:
      return {
        ...initialState,
        user: {},
        token: null,
        isAuth: false,
        status: undefined,
      };
    default:
      return state;
  }
};

export default userReducer;
