import React, { useState, useEffect, useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Button } from '@buffetjs/core';

import apiClient from 'utils/feathersClient';

import { CommentsItem, CategoryType } from 'types/components/comments';

type AddCommentProps = {
  categoryType: CategoryType;
  categoryId?: number;
  countryId?: number;
  comment?: CommentsItem;
  whenCommentAdded?: () => void;
  isTypeRecord?: boolean;
};

const AddComment: React.FC<AddCommentProps> = props => {
  const { categoryType, categoryId, isTypeRecord, comment, countryId, whenCommentAdded } = props;
  const replyId = comment && comment.id ? comment.id : null;

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = useState<string>('');

  const addComment = async () => {
    if (!value.trim()) return false;

    let service: string = '';
    let data: any = {};
    if (isTypeRecord) {
      service = 'content/record-comments';
      data = {
        type: comment.type,
        entityId: comment.entityId,
        text: value.trim(),
        replyId,
      };
    } else {
      service = 'comments';
      data = {
        [`${categoryType}Id`]: categoryId,
        text: value.trim(),
        replyId,
        countryId,
      };
    }

    await apiClient.service(service).create(data);

    setValue('');
    if (whenCommentAdded && comment) {
      whenCommentAdded();
    }
    inputRef.current.focus();
  };

  useEffect(() => {
    if (replyId) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="comments__add">
      <TextareaAutosize
        ref={inputRef}
        rows={10}
        minRows={2}
        placeholder="Enter a comment"
        value={value}
        onChange={e => setValue(e.target.value)}
      />
      <Button label="Add" onClick={addComment} />
    </div>
  );
};

AddComment.defaultProps = {
  whenCommentAdded: () => {},
  isTypeRecord: false,
  comment: null,
  categoryId: null,
  countryId: null,
};

export default AddComment;
