/**
 *
 * WysiwygBottomControls
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { Span, Wrapper } from './components';

const WysiwygBottomControls = ({ isPreviewMode, onChange, onClick }) => {
  const [t] = useTranslation();
  const bottomText = t('components.WysiwygBottomControls.uploadFiles');

  return (
    <Wrapper>
      <div>
        <label
          className="dropLabel"
          onClick={e => {
            if (isPreviewMode) {
              e.preventDefault();
            }
          }}
        >
          {bottomText.slice(0, bottomText.indexOf('{browse}'))}
          <Span>{t('components.WysiwygBottomControls.uploadFiles.browse')}</Span>
          {bottomText.replace('{browse}', '').slice(bottomText.lastIndexOf('{browse}'))}
          <input type="file" onChange={onChange} />
        </label>
      </div>
      <div className="fullScreenWrapper" onClick={onClick}>
        {t('components.WysiwygBottomControls.fullscreen')}
        <FontAwesomeIcon style={{ marginLeft: '7px' }} icon={faExpand} />
      </div>
    </Wrapper>
  );
};

WysiwygBottomControls.defaultProps = {
  isPreviewMode: false,
  onChange: () => {},
  onClick: () => {},
};

WysiwygBottomControls.propTypes = {
  isPreviewMode: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

export default WysiwygBottomControls;
