import React, { CSSProperties } from 'react';
import './index.css';

type LoadingProps = {
  style?: CSSProperties;
  className?: string;
};

export default ({ style, className }: LoadingProps) => (
  <div className={`loader-wrapper ${className}`} style={style}>
    <div className="custom-loader" />
  </div>
);
